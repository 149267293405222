import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import { Img } from "../Img";
import { Text } from "../Text";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/system";
import notification from "../../assets/notification.png";
import { Stack } from "@mui/system";
import Button from "@mui/material/Button";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
import { updateOnlineStatus } from "../../Firebase.jsx";
import "./Header2.css";
import { getTokenFromLocalStorage } from "../authService";
import { useUserAuth } from "../../context/userAuthContext";
import imagehed from "../../assets/img_group1171274834.svg";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Modal } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ProfilePage from "../../Components/pages/ProfileSection/ProfilePage";
// import Header from './Header.js'
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";

import { Avatar } from "@mui/material";
const Header2 = ({ setHeaderData, setHeaderLoading }) => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [profileUrl, setProfileUrl] = React.useState(
    "https://api.accompanied.ca/public/placeholder/user_image.jpg"
  );
  const { error, SendDataToFirebase, currentuser } = useUserAuth();
  const [userIdExists, setUserIdExists] = React.useState(false);
  const [renewMembershipStatus, setRenewMembershipStatus] = React.useState(1);
  const [renewMembershipPopup, setRenewMembershipPopup] = React.useState(false);
  const [userId, setUserId] = useState(null);
  // const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleLogout = async () => {
    navigate("/");

    localStorage.removeItem("authToken");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    // Ensure that setHeaderData and setHeaderLoading are available
    if (!setHeaderData || !setHeaderLoading) {
      return;
    }

    const fetchHeaderData = async () => {
      const token = getTokenFromLocalStorage();
      const apiUrl = "header";

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status === 200 && response.data.success) {
          const responseData = response.data.data;
          const newNotificationCount = responseData.notification_count;
          const newProfileUrl = responseData.profile_url;
          const newProfileUrl1 = responseData.image_2;
          const newProfileUrl2 = responseData.image_3;
          const newProfileUrl3 = responseData.image_4;

          // console.log("Profile URL:", newProfileUrl);
          const newUserExists = responseData.user_email;
          const userId = responseData.user_id;

          setNotificationCount(newNotificationCount);
          setProfileUrl(newProfileUrl);
          setUserIdExists(newUserExists);
          setRenewMembershipStatus(response.data.data.membership_status);
          setUserId(userId);
          // setDirectionUserId(responseData.user_id);
          await SendDataToFirebase(
            responseData.user_email,
            responseData.user_name,
            111111,
            false,
            newProfileUrl,
            newProfileUrl1,
            newProfileUrl2,
            newProfileUrl3
          );
          setHeaderData({
            loadingData: responseData,
          });
        } else {
          console.error("Failed to get data:", response.data.message);
        }
      } catch (error) {
        console.error("Error while making the API request:", error.message);
      } finally {
        // Set loading to false regardless of success or failure
        setHeaderLoading(false);
      }
    };

    fetchHeaderData();
  }, [setHeaderData, setHeaderLoading]);

  React.useEffect(() => {
    if (
      renewMembershipStatus === 2 &&
      window.location.pathname !== "/membership"
    ) {
      setRenewMembershipPopup(true); // Open the modal if membershipStatus is 2 and not on the "Membership" route
    } else {
      setRenewMembershipPopup(false); // Close the modal otherwise
    }
  }, [renewMembershipStatus]);

  const handleRenewMembership = () => {
    setRenewMembershipPopup(false);
    navigate("/membership");
  };

  const handleNotificationClick = async () => {
    try {
      // Call the function to update notificationCount (in this case, fetchHeaderData)
      // await fetchHeaderData();

      // Now, navigate to the /notification route using useNavigate
      setNotificationCount(0);
      navigate("/notification");
    } catch (error) {
      console.error("Error updating notification count:", error);
    }
  };

  const linkStyle = {
    textAlign: "center",
  };

  return (
    <>
      <header className="bg-gradient-to-r from-purple-200 to-red-250 flex md:flex-col flex-row font-lato items-center justify-center mb-[-32px] mx-auto w-full z-[1] shadow-lg">
        <style>
          {`
          @media screen and (max-width: 899px) {
            .md\\:flex-col {
              flex-direction: column;
            }
          }
        `}
        </style>
        <div className="bg-header flex md:flex-1 flex-col items-center justify-start ml-10 md:ml-[0] rounded-md w-[10%] md:w-full">
          <style>
            {`
    @media (max-width: 899px) {
      .md\\:w-full {
        width: 100%;
      }
    }
  `}
          </style>
          <div className="flex flex-col md:flex-col items-center justify-start w-full md:w-[69%]">
            <Img
              className="h-[30px]"
              //="images/img_group1171274834.svg"
              src={imagehed}
              alt="group1171274834"
            />
            <Text
              className="mt-[7px] text-[8.87px] text-white-A700 tracking-[2.22px]"
              size="txtLatoBold887"
            >
              ACCOMPANIED
            </Text>
            <Text
              className="text-[4px] text-white-A700_8c tracking-[1.11px] text-center"
              size="txtInterRegular443"
            >
              LIVELY. SAVVY. PEOPLE
            </Text>
          </div>
        </div>
        <div className="flex md:flex-1 flex-col items-center justify-start md:ml-[0] ml-[10px] md:mr-[31px] w-[100%] md:w-full">
          <div className="flex md:flex-row font-inter items-center justify-between">
            <ul className="flex md:flex-col flex-row gap-10 md:hidden items-start justify-start w-auto common-row-list hideOnMediumScreens">
              <li>
                <NavLink to="/" className="text-md nav-link">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/create_event" className="text-md nav-link">
                  Create Event
                </NavLink>
              </li>

              <li>
                <NavLink to="/accepted_events" className="text-md nav-link">
                  Accepted Events
                </NavLink>
              </li>

              <li>
                <NavLink to="/my_events" className="text-md nav-link">
                  My Events
                </NavLink>
              </li>
              <li>
                <NavLink to="/chat" className="text-md nav-link">
                  Chat
                </NavLink>
              </li>

              <li>
                <NavLink to="/membership" className="text-md nav-link">
                  Membership
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Stack
          direction="row"
          sx={{
            marginRight: { xs: 0, md: 3, lg: "15px", xl: "15px" },
            "@media screen and (max-width: 768px)": {
              // width: "100%",
              justifyContent: "space-between",
              marginRight: { xs: 0, md: 0 },
            },
          }}
        >
          <Toolbar disableGutters>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "space-between", // Use space-between to push the icon to the left
                paddingRight: "10px", // Add some right padding for spacing
                "@media (max-width: 900px)": {
                  flexDirection: "column", // Change flex direction to column for screen sizes up to 900px
                  alignItems: "center", // Center items in the column
                },

                "@media (max-width: 768px)": {
                  display: "flex", // Hide the box for screen sizes up to 768px
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ width: "30px", height: "30px" }} />
              </IconButton>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/home" style={linkStyle}>
                  <Typography textAlign="center">Home</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/create_event" style={linkStyle}>
                  <Typography textAlign="center">Create Event</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/accepted_events" style={linkStyle}>
                  <Typography textAlign="center">Accepted Events</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/my_events" style={linkStyle}>
                  <Typography textAlign="center">My Events</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/chat" style={linkStyle}>
                  <Typography textAlign="center">Chat</Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/membership" style={linkStyle}>
                  <Typography textAlign="center">Membership</Typography>
                </Link>
              </MenuItem>
            </Menu>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "flex-end",
                "& > :not(:last-child)": {
                  marginRight: "10px",
                },
              }}
            ></Box>
          </Toolbar>
          <div className="flex md:flex-row font-inter items-center">
            <div
              style={{
                marginRight: "16px",
                display: { xs: "none", md: "block" },
              }}
            >
              <Link to="/home">
                <DashboardCustomizeOutlinedIcon
                  style={{ color: "#863895", fontSize: "36px" }} // Change color and font size here
                />
              </Link>
            </div>
            <Link
              to="/notification"
              className="mr-4"
              onClick={handleNotificationClick}
            >
              {/* Added margin-right for spacing */}
              <div className="relative inline-block">
                <div className="custom-icon-container">
                  <NotificationsActiveOutlinedIcon className="custom-icon" />
                </div>
                {notificationCount > 0 && (
                  <div
                    style={{ backgroundColor: "#863895", color: "#ffffff" }}
                    className="rounded-full w-[24px] absolute top-1 right-1 transform translate-x-1/2 -translate-y-1/2 px-2"
                  >
                    {notificationCount}
                  </div>
                )}
              </div>
            </Link>

            <div className="flex items-center flex-col">
              <Link to="/profile" className="inline-block">
                <Avatar
                  alt=""
                  src={profileUrl}
                  style={{ width: "60px", height: "60px" }}
                />
              </Link>
            </div>
            {/* <div className="flex items-center flex-col">
  <Link to="/profile" className="inline-block">
    <img
      alt=""
      src={profileUrl}
      style={{
        width: "60px",
        height: "60px",
        borderRadius: "50%", // This creates a circular shape
        objectFit: "cover", // This ensures the image fills the circular shape
      }}
    />
  </Link>
</div> */}
          </div>
        </Stack>
        {/* <ProfilePage
          handleLogout={handleLogout}
          open={open}
          setOpen={setOpen}
        /> */}
      </header>
      {renewMembershipStatus === 2 && (
        <Modal
          open={renewMembershipPopup}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Card content */}
          <Card
            sx={{
              width: "500px", // Dimension of the card
              height: "450px", // Dimension of the card
              textAlign: "center",
              borderRadius: "10px", // Square-shaped modal
              overflow: "hidden", // Hide any overflow
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/images/verify.png"
                alt="Your Image"
                style={{
                  maxWidth: "100%", // Maximum width of the image
                  maxHeight: "400px", // Maximum height of the image
                  width: "auto", // Maintain aspect ratio
                  height: "auto", // Maintain aspect ratio
                  margin: "auto", // Center the image horizontally
                }}
              />

              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: "#2F2F2F",
                  textAlign: "center",
                  fontFamily: "Lora",
                  fontSize: "20px", // Initial font size
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  marginTop: 2,
                }}
              >
                Oh no! Your 3 month free membership has expired. You can choose
                from 3 subscription plans to continue using this website.
              </Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#863895",
                    "&:hover": {
                      backgroundColor: "#863895",
                    },
                    marginRight: "0.5rem", // Add spacing between buttons
                  }}
                  variant="contained"
                  onClick={handleRenewMembership}
                >
                  Get Subscription
                </Button>
                <Button
                  variant="contained"
                  onClick={handleLogout}
                  sx={{
                    backgroundColor: "#863895",
                    "&:hover": {
                      backgroundColor: "#863895",
                    },
                    marginRight: "0.5rem", // Add spacing between buttons
                  }}
                >
                  <ExitToAppOutlinedIcon
                    sx={{
                      color: "#fff",
                      fontSize: "1.25rem", // Set icon size to match button
                      marginRight: "0.25rem", // Adjust spacing between icon and button text
                    }}
                  />
                  Logout
                </Button>
              </div>
            </CardContent>
          </Card>
        </Modal>
      )}
      {/* <Header userId={userId} /> */}
    </>
  );
};

export default Header2;

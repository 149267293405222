import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";

const ForgetPassword = ({
  isRecoverDialogOpen,
  setRecoverDialogOpen,
  recoverEmail,
  setRecoverEmail,
  openOtpPopup,
  setOpenOtpPopup,
}) => {
  const [recoverLoading, setRecoverLoading] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  const handleCloseRecoverDialog = () => {
    setRecoverDialogOpen(false);
  };

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    // setRecoverLoading(true);
    // Email pattern regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!recoverEmail) {
      setIsToastOpen(true);

      toast.error("Email is required");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3700);
      setRecoverLoading(false);
      return;
    }
    
  
    if (!emailPattern.test(recoverEmail)) {
      setIsToastOpen(true);

      toast.error("Please enter a valid email");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3700);
      setRecoverLoading(false);
      return;
    }
   
  
    try {
      setRecoverLoading(true);

      const response = await axios.post("forgetPassword", {
        email: recoverEmail,
      });
  
      if (response.status === 200 && response.data.success) {
        toast.success("OTP sent successfully");
        setRecoverLoading(false);
        setRecoverDialogOpen(false);
        setOpenOtpPopup(true);
      } else {
        // Display custom error message from the API response
        toast.error(response.data.message || "An error occurred");
        setRecoverLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
      setRecoverLoading(false);
    }
  };
  

  return (
    <div>
      <Dialog
        //  backdrop="static"
        open={isRecoverDialogOpen}
        // onClose={handleCloseRecoverDialog}
        maxWidth="xs"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          borderRadius: "20px",
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            position: "relative",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseRecoverDialog}
            aria-label="close"
            sx={{
              position: "absolute",
              top: "8px",
              right: "12px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <p
              style={{
                color: "#09090B",
                fontFamily: "Lora",
                fontSize: "33.034px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                marginTop: "75px",
              }}
            >
              Forgot Password
            </p>
            <p
              style={{
                color: "#464646",
                fontFamily: "Lora",
                fontSize: "13.727px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20.59px",
                textAlign: "center",
                // marginTop: "25px",
              }}
            >
              No problem! Enter the email address you used to sign up, and we'll
              help you reset your password.
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ marginTop: "55px" }}
              >
                Email
              </Typography>
              <TextField
                placeholder="Please enter your email"
                value={recoverEmail}
                onChange={(e) => {
                  setRecoverEmail(e.target.value);
                }}
                // variant="standard"
                fullWidth
                sx={{ width: "100%" , "& fieldset": {
                  borderColor: "#863895 !important",
                },
                "& [type='text']:focus": {
                  boxShadow: 'none', // Remove box shadow on focus for type='text'
                }, }}
              />
            </Grid>
            <Grid item xs={8} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRecoverPassword}
                disabled={recoverLoading || isToastOpen}
                sx={{
                  backgroundColor: "#863895 !important",
                  color: "#fff",
                  marginTop: "10px",
                  marginBottom: "75px",
                }}
              >
                {recoverLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                Recover Password
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </div>
  );
};

export default ForgetPassword;

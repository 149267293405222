import React, { useEffect, useState, useRef } from "react";
import { initFirebase } from "../Firebase";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { Img } from "./Img";
import { Line } from "./Line";
import { Text } from "./Text";
import Header from "./Header/Header";
import Footer from "./FooterPages/Footer";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styled from "styled-components";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import info_video_img from "../assets/info_video_img.jpeg.jpg";
import arrow_play from "./Img/arrow_play.png";
import SendIcon from "@mui/icons-material/Send";
import infocard1 from "./Img/infocard1.png";
import infocard2 from "./Img/infocard2.png";
import Hero from "./Img/Hero.png";
import infocard3 from "./Img/infocard3.png";
import playImg from "../assets/play.png";
import info5 from "../assets/info5.png";
import info6 from "../assets/info6.png";
import info7 from "../assets/info7.png";
import info8 from "../assets/info8.png";
import infoPc from "../assets/info_main.png";

import "../fonts.css";

const InformationPage1Page = () => {
  const [videoVisible, setVideoVisible] = useState(false);

  const toggleVideo = () => {
    setVideoVisible(!videoVisible);
  };
  const videoRef = useRef(null);

  const handleOverlayClick = () => {
    // If video ref exists and it has a current property
    if (videoRef.current && videoRef.current.click) {
      videoRef.current.click();
    }
  };

  const bull = (
    <Box
      component="span"
      sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
      •
    </Box>
  );
  useEffect(() => {
    initFirebase();
    return () => {};
  }, []);

  const overlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "46.667px",
    height: "46.667px",
    flexShrink: 0,
  };

  const ResponsiveText = styled.p`
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    color: var(--General-Light, #fff);
    font-size: 49.387px;

    @media only screen and (max-width: 550px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 550px) and (max-width: 1024px) {
      font-size: 32px;
    }
  `;
  const ResponsiveText2 = styled.p`
    -webkit-text-stroke: 1.37px #ffffff;
    color: transparent;
    font-family: "Montserrat-Bold", Helvetica;
    font-size: 84px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.5;
    text-align: center;
    top: 0;

    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }

    @media only screen and (min-width: 601px) and (max-width: 707px) {
      font-size: 32px;
    }

    @media only screen and (min-width: 707px) and (max-width: 1024px) {
      font-size: 36px;
    }
  `;

  const ResponsiveTextContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
  `;
  const StyledParagraph = styled.p`
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    margin-top: 8px !important;
    max-width: 665px;
    font-size: 16px;
    line-height: 26px; /* 26.066px on the base font size of 16.462px */

    @media only screen and (max-width: 660px) {
      font-size: 14px;
      margin-top: 10px;
      line-height: 1.5; /* Adjust the line-height for small screens */
    }

    @media only screen and (min-width: 660px) and (max-width: 1024px) {
      font-size: 16px;
      margin-top: 5px;
      line-height: 1.4; /* Adjust the line-height for medium screens */
    }

    @media only screen and (min-width: 1025px) {
      font-size: 16px;
    }

    .line {
      display: block; /* Ensure each line is displayed as a block, forcing line breaks */
      text-align: center;
      font-size: 16px;

      @media only screen and (min-width: 660px) {
        font-size: 16px; /* Adjust font size for the first line on medium screens */
      }
    }
  `;

  const ResponsiveText1 = () => {
    return (
      <StyledParagraph>
        <span className="line first-line">
          You never have to sit at home,&nbsp;wishing there was someone to go
          out and have fun with.&nbsp;
        </span>
        <span className="line second-line">
          Movie night, wine tasting or a beach day. You'll discover that the
          magic lies{" "}
        </span>
        <span className="line third-line">
          {" "}
          in connections you make along the way.
        </span>
      </StyledParagraph>
    );
  };

  return (
    <>
      <div className="flex flex-col font-montserrat items-center justify-start mx-auto w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="bg-white-A700 flex flex-col items-end justify-start w-full">
            <Header />
            <div
              className="relative w-full"
              style={{
                top: 43,
              }}
            >
              <div className="md:h-[560px] mt-auto mx-auto w-full relative">
                <Img
                  className="h-[560px] m-auto object-cover w-full"
                  src={infoPc}
                  alt="img"
                  style={{ position: "relative" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(181deg, rgba(71, 67, 64, 0.4) -35.38%, rgba(36, 36, 38, 0.76) 4.74%, rgba(26, 26, 26, 0.33) 87.65%)",
                  }}
                ></div>
                <div className="absolute flex flex-col gap-4 inset-x-0 items-center justify-start mx-auto top-10 w-full md:w-[47%]">
                  <div className="flex flex-col gap-2 items-center justify-center max-w-[680px] w-full">
                    <>
                      <ResponsiveText>
                        <span className="text-white-A700 font-montserrat font-bold">
                          Going Alone Is Great.{" "}
                        </span>
                        <span className="text-blue-A200_02 font-montserrat font-bold">
                          Going Together{" "}
                        </span>
                        <span className="text-deep_orange-200 font-montserrat font-bold"></span>
                        <span className="text-white-A700 font-montserrat font-bold">
                          Is Better.
                        </span>
                      </ResponsiveText>
                    </>
                    <ResponsiveText1>
                      You never have to sit at home,wishing there was someone to
                      go out and have fun with. Movie night, wine tasting, or a
                      beach day. You'll discover that the magic lies in
                      connections you make along the way.
                    </ResponsiveText1>
                  </div>
                  <Link to="/sign_up">
                    {" "}
                    <Button
                      style={{ minWidth: "260px", marginTop: "12px" }}
                      className="capitalize cursor-pointer font-bold leading-[normal] md:min-w-[400px] rounded-[24px] text-[22px] text-center sm:text-md md:text-md"
                      size="sm"
                      variant="gradient"
                      color="blue_A200_02_indigo_A200_d3"
                    >
                      Create Event
                    </Button>
                  </Link>
                  <ResponsiveTextContainer>
                    <ResponsiveText2>Lively. Savvy. People.</ResponsiveText2>
                  </ResponsiveTextContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card
              sx={{
                margin: 7,
                borderRadius: 7,
                background: "#EDF1FE",
              }}
            >
              <CardContent
                sx={{
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack mb={2}>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={infocard1}
                  ></img>
                </Stack>
                <Stack
                  spacing={2}
                  textAlign="center"
                  style={{ marginTop: "10px" }}
                >
                  <Typography
                  //  sx={{ mb: 1.5, fontFamily: "Lato" }}
                  >
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#172048",
                      }}
                    >
                      100% Privacy
                    </p>

                    <p
                      style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#4A4A4A",
                        marginTop: "10px",
                      }}
                    >
                      The safety and security of your data is important to us.
                      This website performs daily backups and uses firewall
                      systems which provide robust protection against potential
                      online threats.
                    </p>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card
              sx={{
                margin: 7,
                borderRadius: 7,
                background:
                  "linear-gradient(180deg, #863895 0.14%, rgba(108, 99, 255, 0.83) 51.44%)",
              }}
            >
              <CardContent
                sx={{
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack mb={2}>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={infocard2}
                  ></img>
                </Stack>
                <Stack
                  spacing={2}
                  textAlign="center"
                  style={{ marginTop: "10px" }}
                >
                  <Typography
                  // sx={{ mb: 1.5, fontFamily: "Lato", color: "white" }}
                  >
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      Membership Benefits
                    </p>
                    <p
                      style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#D4D4D4",
                        marginTop: "10px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      New members get 3 months free access.
                      {"\n"} {/* Line break */}
                      Members have complete access to the entire site. Sign up
                      to post and respond to events, review profiles of possible
                      invitees and connect via the chat feature.
                    </p>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card
              sx={{
                height: 350,
                margin: 7,
                borderRadius: 7,
                background: "#EDF1FE",
              }}
            >
              <CardContent
                sx={{
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Stack mb={2}>
                  <img
                    style={{ width: 100, height: 100 }}
                    src={infocard3}
                  ></img>
                </Stack>
                <Stack
                  spacing={2}
                  textAlign="center"
                  style={{ marginTop: "10px" }}
                >
                  <Typography sx={{ mb: 1.5, fontFamily: "Lato" }}>
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#172048",
                      }}
                    >
                      Interact
                    </p>
                    <p
                      style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#4A4A4A",
                        marginTop: "10px",
                      }}
                    >
                      Chat with people who've shown interest in accompanying you
                      before deciding to move your interaction offline.
                      Ghosters' profiles may be tagged. Be safe. Go out. Have
                      fun!
                    </p>
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} sx={{ backgroundColor: "#EDF1FE" }}>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: 700,
              fontFamily: " Lora",
              marginTop: 12,
              textAlign: "left",
              marginLeft: "20px",
              "@media (max-width: 900px)": {
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
              },
            }}
          >
            About Accompanied
          </Typography>
          <Typography
            sx={{
              fontSize: "14px !important",
              fontWeight: 400,
              fontFamily: "Raleway !important",
              // fontFamily: "Lato",

              marginTop: 5,
              lineHeight: "24px",
              textAlign: "left",
              margin: "21px",
              opacity: 0.7,
              // "@media (max-width: 900px)": {
              //   marginLeft: "auto",
              //   marginRight: "auto",
              // },
            }}
          >
            Ever wanted to do something but knew it would be much more enjoyable
            if you had someone to do it with? Maybe your friends are busy or
            you're new to the area. Whatever the reason, instead of relying on
            traditional methods of meeting new people, this website can connect
            you with individuals who are eager to have fun and make memories.
            Whether you want to check out a local art exhibit or grab a mimosa
            and chat, Accompanied provides a space for meaningful connections to
            flourish. Simply post an activity and choose from a list of
            interested persons, who you’d like to accompany you. Let's dive in
            and discover how this website can transform your social life!
          </Typography>

          <Link to="/contact_us">
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[197px] rounded-lg ml-4 md:ml-0 mt-20 md:mt-0"
              rightIcon={
                <Img
                  className="h-6 ml-3"
                  src="images/img_arrowright.svg"
                  alt="arrow_right"
                />
              }
              shape="round"
              color="purple_600"
              size="md"
              variant="fill"
            >
              <div className="font-bold leading-[normal] text-base text-left">
                Contact Us
              </div>
            </Button>
          </Link>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <div className="sm:h-[1014px] md:h-[546px] h-[550px] relative w-[100%] md:w-full">
            <Img
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
              className="absolute h-[223px] left-[-22px] object-cover rounded-[19px] top-[7%] w-[53%] md:w-[53%] sm:w-[51%] sm:h-[216px] sm:left-[14px] !important"
              src={info7}
              alt="img_Five"
            />
            <Img
              className="absolute bottom-[7%] md:bottom-[10%] h-[219px] object-cover right-[82px] rounded-[16px] w-[44%] md:w-[53%] sm:w-[50%] sm:h-auto sm:right-[12px] !important"
              src={info8}
              alt="img_Six"
            />
            <Img
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
              className="absolute h-[243px] object-cover right-[72px] rounded-[19px] top-[1%] w-[36%] md:w-[36%] sm:h-[226px] sm:right-[17px] !important"
              src={info6}
              alt="img_Seven"
            />
            <Img
              style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
              className="absolute bottom-[-2%] md:bottom-[0] h-[250px] left-[-4px] object-cover rounded-[19px] w-[38%] md:w-[41%] sm:left-[8px] !important"
              src={info5}
              alt="img_Eight"
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ marginTop: 4, marginBottom: 4, backgroundColor: "#FFF" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              style={{
                maxWidth: "100%",
                height: "auto",
                display: videoVisible ? "none" : "block",
              }}
              src={info_video_img}
              alt="infovideo"
            />
            {videoVisible && (
              <div className="video-container">
                <video
                  controls
                  className="video-player"
                  onEnded={() => setVideoVisible(false)}
                  autoPlay
                >
                  <source src="/video/Skype_Video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            <Img
              style={{
                ...overlayStyle,
                display: videoVisible ? "none" : "block",
                cursor: "pointer",
              }}
              src={playImg}
              alt="overlay"
              onClick={() => setVideoVisible(true)}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "@media (max-width: 900px)": {
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        >
          <span
            style={{
              "@media (max-width: 900px)": {
                marginLeft: "auto",
                marginRight: "auto",
              },
            }}
          >
            <Typography
              variant="h5"
              mb={4}
              sx={{ fontFamily: "Lato", margin: "8px", fontWeight: 400 }}
            >
              Here’s a short video overview of how easy it is to create an event
            </Typography>
            <Typography
              paragraph
              sx={{ mb: 1.5, fontFamily: "Lato", margin: "8px", opacity: 0.7 }}
            >
              ✓ Sign up & sign in. New users get 3 months free membership
            </Typography>
            <Typography
              paragraph
              sx={{ mb: 1.5, fontFamily: "Lato", margin: "8px", opacity: 0.7 }}
            >
              ✓ Create event/activity
            </Typography>
            <Typography
              paragraph
              sx={{ mb: 1.5, fontFamily: "Lato", margin: "8px", opacity: 0.7 }}
            >
              ✓ Review potential invitees. A person showing interest in your
              event unlocks the chat feature
            </Typography>
            <Typography
              paragraph
              sx={{ mb: 1.5, fontFamily: "Lato", margin: "8px", opacity: 0.7 }}
            >
              ✓ Mutually decide on who you’d like to accompany you to your event
            </Typography>
            <Typography
              paragraph
              sx={{ mb: 1.5, fontFamily: "Lato", margin: "8px", opacity: 0.7 }}
            >
              ✓ Be safe and have fun!
            </Typography>

            <Typography
              variant="subtitle2"
              mb={4}
              sx={{
                mb: 1.5,
                fontFamily: "Lato",
                marginTop: "40px",
                marginLeft: "5px",
                marginBottom: "12px",
                opacity: 0.7,
              }}
            >
              *Because we’d like to facilitate only authentic connections,
              ghosters’ profiles may be visibly tagged.
            </Typography>
          </span>
          <Stack spacing={1} direction="row">
            <div>
              <Button
                className="cursor-pointer flex items-center justify-center min-w-[197px] rounded-lg ml-4 md:ml-0"
                rightIcon={
                  <Img className="h-6 ml-3" src={arrow_play} alt="arrow_play" />
                }
                shape="round"
                color="purple_600"
                size="md"
                variant="fill"
                onClick={toggleVideo}
              >
                <div className="font-bold leading-[normal] text-base text-left">
                  {videoVisible ? "Close Video" : "Watch Video"}
                </div>
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card
            sx={{
              boxShadow: "none !important",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFF",
              }}
            >
              <Link to="/sign_up" style={{ textDecoration: "none" }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xs: 24, md: 30 },
                    marginBottom: 0,
                    display: "inline-block",
                  }}
                  color="text.secondary"
                  gutterBottom
                  fontWeight={700}
                >
                  Sign Up!
                </Typography>
              </Link>
              <Divider
                sx={{
                  backgroundColor: "#863895",
                  marginY: 1,
                  width: "10%",
                  display: "inline-block",
                  height: 2,
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "center",
                  // color: "#000000",
                  m: 2,
                }}
              >
                Sign up today and enjoy an exclusive, free trial period,
                including 3 months of complimentary membership. Whether you're
                looking for a hiking buddy,
                <br /> someone to chat with or a person to share an experience,
                there's a good chance you'll find who you're looking for on this
                site. <br />
                Begin your journey with Accompanied and start connecting with
                lively, savvy, people.
              </Typography>

              <div sx={{ mt: 2 }} />
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button
                  className="cursor-pointer font-bold font-raleway leading-[normal] md:min-w-[120px] rounded-lg text-[16px] text-center sm:text-lg md:text-xl"
                  shape="round"
                  color="purple_600"
                  size="md"
                  variant="fill"
                  style={{
                    display: "inline-flex",
                    padding: "15px 40px",
                    alignItems: "center",
                    gap: "12px",
                    borderRadius: "8px",
                    background: "#863895",
                    boxShadow: "0px 10px 30px 0px rgba(56, 29, 219, 0.20)",
                  }}
                >
                  Sign In
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div style={{ marginTop: 24 }} />
      <Footer />

      <script src="https://cdn.tailwindcss.com?plugins=forms,typography,aspect-ratio,line-clamp"></script>
    </>
  );
};

export default InformationPage1Page;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, TextField, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { saveTokenToLocalStorage, AUTH_TOKEN_KEY } from "./authService";

const OtpPopup = ({
  openOtpPopup,
  setOpenOtpPopup,
  isForgetPassword,
  email,
  setIsForgetPassword,
  setShowResetPasswordPopup,
  setApiSuccess,
}) => {
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = React.useState(["", "", "", "", "", ""]);
  const [otpLoading, setOtpLoading] = React.useState(false);

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    user_type: "1",
    login_type: "1",
    otp: "",
  });

  const handleDialogClose = () => {
    setOtpValues(["", "", "", "", "", ""]);
    setOpenOtpPopup(false);
    if (isForgetPassword) {
      setIsForgetPassword(false);
    }
  };

  const handleSubmit = (email1) => {
    setOtpLoading(true);
    const otp = otpValues.join("");
    console.log(email1);
    axios
      .post("otpVerify", {
        email: email1,
        otp: otp,
      })
      .then((response) => {
        const apiResponse = response.data;
// debugger
        if (response.status === 200 && apiResponse.success) {
          // console.log("OTP verification successful:", response.data);
        const token = response.data.token;
        const userId = response.data.data.id;
          saveTokenToLocalStorage(token,userId);
          setOtpLoading(false);
          setOpenOtpPopup(false);
          // debugger98
          if (setApiSuccess) {
            setApiSuccess(true);
          }
          if(setApiSuccess === null || setApiSuccess === undefined){
            // debugger
            toast.success("OTP verified successfully");
          }
          
          if (!isForgetPassword && setApiSuccess === null) {
            navigate("/home");
          } else if(setShowResetPasswordPopup !== null){
            setShowResetPasswordPopup(true);
          }
          setOtpValues(["", "", "", "", "", ""]);
        }    else if (response.data.status === 400 && apiResponse.message.toLowerCase().includes("invalid otp")) {
          setOtpLoading(false);
          setOtpValues(["", "", "", "", "", ""]);
          toast.error("Invalid OTP. Please enter a valid OTP.");
        } else if (response.data.status === 400 && apiResponse.message.toLowerCase().includes("otp expired")) {
          // debugger
          setOtpLoading(false);
          setOtpValues(["", "", "", "", "", ""]);
          toast.error("OTP Expired. Please resend OTP");
        } else if (response.status === 200 && !apiResponse.success) {
          setOtpLoading(false);
          setOtpValues(["", "", "", "", "", ""]);
          // if (apiResponse.message.toLowerCase().includes("invalid email")) {
          //   setOtpLoading(false);
          //   toast.error("Invalid email. Please check your email address.");
          // } else {
          //   setOtpLoading(false);
          //   toast.error("Failed to verify OTP. " + apiResponse.message);
          // }
        } else {
          setOtpLoading(false);
          console.error("Unexpected response from the server:", response);
          setOtpValues(["", "", "", "", "", ""]);
          toast.error("Failed to verify OTP. Please try again.");
        }
      })
      .catch((error) => {
        setOtpLoading(false);
        console.error("Error during OTP verification:", error);
        toast.error("Failed to verify OTP. Please try again.");
      });
  };

  const handleResendOTP = async (email) => {
    setOtpLoading(true);
    setOtpValues(["", "", "", "", "", ""]);
    try {
      const response = await axios.post(
        "resendotp",

        {
          email: email,
        }
      );

      if (response.status === 200) {
        toast.success("OTP Resend successfully");
        setOtpLoading(false);
      } else {
        alert("Signup failed. Please try again.");
        setOtpLoading(false);
      }

      console.log("API Response:", response.data);
    } catch (error) {
      setOtpLoading(false);
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error("OTP Not Send. Please try again.");
    }
  };

  const handleOtpChange = (e, index, field) => {
    const inputValue = e.target.value.replace(/\D/, "");

    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = inputValue;

      if (inputValue !== "" && index < newValues.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      } else if (inputValue === "" && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }

      return newValues;
    });
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  return (
    <div>
      <Dialog
        open={openOtpPopup}
        // onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
        className="max-w-full sm:w-600 sm:h-600"
      >
        <DialogTitle>
          <div className="flex justify-between items-center">
            <div>
              <Typography variant="h5" className="font-serif font-bold">
                Enter OTP
              </Typography>
            </div>
            <div className="ml-auto">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="font-serif font-normal">
          <Typography variant="body2" gutterBottom>
            OTP sent to <span className="text-purple-700">{email}</span>
          </Typography>

          <form className="otp-container">
            <div className="flex justify-center space-x-2 mb-4">
              {otpValues.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-input-${index}`}
                  className="otp-input"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  inputProps={{ maxLength: 1 }}
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "8px",
                    backgroundColor: "rgba(134, 56, 149, 0.1)",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                  sx={{
                    "& [type='text']:focus": {
                      boxShadow: 'none', // Remove box shadow on focus for type='text'
                      borderColor: "#863895 !important",
                    },
                  }}
                />
              ))}
            </div>
            <div className="flex items-center justify-between mb-4">
              {/*<Typography variant="body2" className="cursor-pointer" onClick={handleSendCode} color="primary">
					<SendIcon fontSize="small" /> Send Code
					</Typography> */}
              <Typography
                variant="body2"
                className="cursor-pointer ml-4"
                onClick={() => handleResendOTP(email)}
                color="primary"
                disabled={otpLoading}
              >
                <RefreshIcon fontSize="small" />
                Resend OTP
              </Typography>
            </div>

            <Button
              type="button"
              onClick={
                () =>
                  // handleSubmit(formData2.recoverEmail)
                  handleSubmit(email)
                // handleResendOTP(email)
              }
              variant="contained"
              color="primary"
              fullWidth
              style={{
                borderRadius: "8.579px",
                backgroundColor: "#953F97",
                color: "#fff",
              }}
              disabled={otpLoading}
            >
              {otpLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}  

      />
    </div>
  );
};

export default OtpPopup;

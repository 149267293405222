import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { List, ListItem, ListItemText } from "@mui/material";
import { getTokenFromLocalStorage } from "../authService";
import Header2 from "../../Components/Header/Header2";
import { MoonLoader } from "react-spinners";

import axios from "axios";

import Footer from "../FooterPages/Footer";
const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "28px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "rgba(16, 24, 40, 0.85)",
      fontFamily: "Raleway",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      color: "#202226",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "31px", // Adjusted line-height
    },
  },
});
const FAQ = () => {
  const [userIdExists, setUserIdExists] = React.useState(false);
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserIdExists(response.data.data.id);
        // debugger
        // console.log("Profile Data:", response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="bg-gray-100_03 flex flex-col font-lato gap-[55px] items-start justify-start mx-auto w-full" style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}>
        {userIdExists ? (
          <Header2
            setHeaderData={setHeaderData}
            setHeaderLoading={setHeaderLoading}
          />
        ) : (
          <Header />
        )}

        <Grid container spacing={3} sx={{ width: "100%", margin: "auto" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              sx={{
                width: "100%",
                borderRadius: "20px",
                margin: "auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                backgroundColor: "rgba(160, 195, 255, 0.03)",
              }}
            >
              <CardContent>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h6">FAQ</Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography
                    variant="h5"
                    mt={2}
                    style={{ position: "relative" }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        marginLeft: "0.2em",
                        top: "0.2em",
                      }}
                    >
                      &bull;
                    </span>
                    What is Accompanied?
                  </Typography>
                </ThemeProvider>

                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Accompanied is a website that connects people who want to do
                    activities together, whether it's a local art exhibit,
                    attending a work function or going for a chat.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How does Accompanied work?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Simply post an activity you'd like to do and choose from a
                    list of interested persons who you'd like to accompany you.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What kind of activities can I do with Accompanied?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Any activity you'd like to do with someone else! Get
                    creative and explore the possibilities.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How can Accompanied help me make meaningful connections?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Accompanied provides a space for people to connect with
                    others who share similar interests, allowing for meaningful
                    connections to flourish. Profiles of persons who practice
                    ghosting are tagged to discourage this act.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    Is membership tiered?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    No. The flat fee provides complete access to the site and
                    all its features. We like to keep things simple!
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What features are available for members?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Members can post and respond to events, review profiles of
                    possible invitees, share their activity directly with a
                    possible invitee, connect via the chat feature and request
                    that a profile be tagged for ghosting.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    Are members able to chat with all other members?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Because we aim to facilitate purposed interactions with
                    persons wanting to share your experience, members are only
                    able to chat if there is mutual interest, i.e. Someone
                    expresses interest in your activity, and you consider their
                    request.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How do I create an event or activity?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    You can create an event or activity by following the
                    instructions or watching the video on the home page.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    Why is there a ghost icon on some profiles?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    A profile can be tagged with a ghost icon if someone ghosts
                    another person by: i. being reported for not showing up to
                    an activity that was mutually accepted ii. cancelling an
                    accepted event within 48 hours of the activity without a
                    valid reason iii. cancelling on an event they accepted
                    within 48 hours of the event without a valid reason.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How long does the ghost tag stay on a profile?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    The ghost tag remains on a profile for 30 days from the date
                    that a justifiable reason was provided for the profile to be
                    tagged.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    <ThemeProvider theme={headingFont}>
                      <Typography variant="h5" mt={2}>
                        Why are profiles of ghosters tagged on this site?
                      </Typography>{" "}
                    </ThemeProvider>
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    This aims to protect our community by discouraging the
                    practice of ghosting. Reciprocity is key to being treated
                    with respect and being made to feel worthy and
                    indispensable.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How can I get in contact with Accompanied?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    You can contact us at any time through our online form, by
                    email info@accompanied.ca , or by phone at 1-587-223-9574
                    Mondays to Fridays 7 a.m. to 5 p.m. MST.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does 'Consider Request' mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    After you've posted an event, persons may express interest
                    in accompanying you. Clicking on 'Consider Request' unlocks
                    the chat feature so that you communicate with the potential
                    invitee. Getting to know persons a little better may help
                    you decide to whom an invitation should be extended.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘Accept Request’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Clicking ‘Accept Request’ indicates that you have decided on
                    that person to accompany you to your event. This also
                    unlocks the chat feature.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘Decline Request’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    After you've posted an event, persons may express interest
                    in accompanying you. Clicking on  ‘Decline Request’ indicates
                    that you will not consider extending an invitation to that
                    profile. This will not unlock the chat feature.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘I'm Interested’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    When someone has posted an event that you would like to
                    attend with the poster, you can express your interest by
                    clicking ‘I'm Interested’. This unlocks the chat feature so
                    that you can communicate with the poster. Getting to know
                    persons a little better may help you decide if you truly
                    would like to accompany them.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does “Withdraw Interest" mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Clicking ‘Withdraw Interest’ removes your profile from
                    consideration as an attendee to a posted event. This locks
                    the chat feature.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘I'm Attending’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    These are the events that you have expressed interest in
                    attending, and your request has been accepted.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘I'd Like to Attend’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    These are the events that you have expressed interest in
                    attending.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    What does ‘Requests Received’ mean?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    These are the people who have expressed interest in
                    accompanying you to your event.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How are events and activities different?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    For the purposes of this site, they can be used
                    interchangeably.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How can I cancel my subscription?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    We're currently in the process of refining our subscription
                    plans and adding a cancellation feature. We appreciate your
                    patience as we work on this.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    How can I delete my account?
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Go to your profile, scroll to the bottom of the page and
                    click ‘Permanently Delete Account’.
                  </Typography>{" "}
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default FAQ;

import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Header2 from "../Header/Header2";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { List, ListItem, ListItemText } from "@mui/material";
import Footer from "../FooterPages/Footer";
import { getTokenFromLocalStorage } from "../authService";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "24px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      color: "#202226",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "31px",
    },
  },
});
const listtextStyle = {
  color: "#202226",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "31px",
};
const SafetyGuidelines = () => {
  // const token = getTokenFromLocalStorage();
  // const navigate = useNavigate();

  // if (!token) {
  //   console.error("Token not found in local storage");
  // //  alert("please login first");
  //   navigate('/login');
  //   return;
  // }
  const [userIdExists, setUserIdExists] = React.useState(false);
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserIdExists(response.data.data.id);
        // debugger
        // console.log("Profile Data:", response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="bg-gray-100_03 flex flex-col font-lato gap-[55px] items-start justify-start mx-auto w-full"  style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}>
        {userIdExists ? (
          <Header2
            setHeaderData={setHeaderData}
            setHeaderLoading={setHeaderLoading}
          />
        ) : (
          <Header />
        )}

        <Grid container spacing={3} sx={{ width: "100%", margin: "auto" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              sx={{
                width: "100%",
                borderRadius: "20px",
                margin: "auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                backgroundColor: "rgba(160, 195, 255, 0.03)",
              }}
            >
              <CardContent>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h6">Safety Guidelines</Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    You cannot control the actions of others but there are
                    things you can do to help keep you safe. Put your safety
                    first and trust your gut and judgment.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Online Safety
                  </Typography>
                </ThemeProvider>
                <List>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Protect your Accompanied.ca account information.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Protect your personal information.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Be mindful of scammers.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Never send or wire money.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Never share financial information.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Take the time to get to know the person before agreeing
                      to meet in person.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Report offensive and suspicious behaviour.
                    </Typography>
                  </ListItem>
                </List>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Meeting in Person
                  </Typography>
                </ThemeProvider>
                <List>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Look up the venue so you're aware of the area, type of
                      venue, etc.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Be in control of getting to and from the venue.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Meet and stay in a public place.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Advise friends or family of your plans.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Drink responsibly and do not leave your drinks
                      unattended.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - If you feel unsafe, leave.
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding>
                    <Typography style={listtextStyle} variant="subtitle2">
                      - Report unsafe behaviours.
                    </Typography>
                  </ListItem>
                </List>

                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Remember, your safety is the most important thing, so always
                    be cautious and aware of your surroundings. By following
                    these safety guidelines, you can help to keep yourself safe
                    and protected.
                  </Typography>{" "}
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default SafetyGuidelines;

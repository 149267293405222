import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Header2 from "../Header/Header2";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getTokenFromLocalStorage } from "../authService";
import axios from "axios";
import { MoonLoader } from "react-spinners";

import { List, ListItem, ListItemText } from "@mui/material";
import Footer from "../FooterPages/Footer";
const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "28px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      color: "#202226",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});
const TermsCondition = () => {
  const [userIdExists, setUserIdExists] = React.useState(false);
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserIdExists(response.data.data.id);
        // debugger
        // console.log("Profile Data:", response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[55px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        {userIdExists ? (
          <Header2
            setHeaderData={setHeaderData}
            setHeaderLoading={setHeaderLoading}
          />
        ) : (
          <Header />
        )}
        <Grid container spacing={3} sx={{ width: "100%", margin: "auto" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              sx={{
                width: "100%",
                borderRadius: "20px",
                margin: "auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                backgroundColor: "rgba(160, 195, 255, 0.03)",
              }}
            >
              <CardContent>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h6">Terms & Conditions</Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    These terms and conditions (the 'Terms and Conditions')
                    govern the use of www.accompanied.ca (the “Site”). This Site
                    is owned and operated by Accompanied Inc and is a social
                    networking website. Using this Site indicates that you have
                    read and understand these Terms and Conditions, Safety
                    Guidelines and agree to abide by them at all times.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Age Restrictions
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    18 years is the minimum age required for using this Site. By
                    using this Site, users agree that they have attained the age
                    of 18 years. We do not assume legal responsibility for false
                    statements about age.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Accounts
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    When you create an account on this Site, you agree to the
                    following: 1. Personal information provided is accurate,
                    truthful and current 2. You are solely responsible for your
                    account, the security and privacy of your account, and
                    information attached to your account.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    User Contributions
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Users may post on our Site, photos, videos, and public
                    comments. By posting publicly on this Site, you agree not to
                    act illegally or violate these Terms and Conditions.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Acceptable Use
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    As a user of this Site, you agree to not use this Site for
                    illegal purposes, to use this Site legally, and to not:
                    (i)Mistreat, Harass or violate the rights of other users of
                    this Site (ii) Act in a manner that could be considered
                    fraudulent (iii) Violate the intellectual property rights of
                    the Site owners or any third party to the Site (iv)Post
                    material that may be deemed inappropriate or offensive.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Indemnity by You
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    To the extent permitted under applicable law, you agree to
                    hold harmless, this Site, the Site owners and the Site
                    creators, from and against all complaints, demands, claims,
                    damages, losses, costs, liabilities, and expenses arising
                    out of your access to or use of this Site and its services.
                    Intellectual Property Published content made available on
                    this Site is proprietary to Accompanied Inc and the Sites
                    creators. This includes, but is not limited to, text, logos,
                    documents, downloadable files, and anything that contributes
                    to the composition of this Site.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Limitation of Liability
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    This Site's owners and Site creators will not be liable for
                    loss of profits, loss of data, use or goodwill resulting
                    from the conduct and/or content of other members or third
                    parties of this Site; your access or inability to access
                    this Site; your use or inability to use this Site;
                    unauthorized use, access or alteration of your content.
                    Safety This Site is not responsible for the conduct of any
                    member or user either on or off of this Site. The Site
                    owners and creators do not inquire into the background of,
                    nor conduct criminal background checks on its members or
                    users. The Site owners and users make no representation or
                    warranties as to the conduct or compatibility of members.
                    You are responsible for using caution in all interactions
                    with other users of this Site. You are to review the Safety
                    Guidelines of this site.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Disclaimers
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    This Site provides the service on an 'as is' and 'as
                    available' basis and does not express nor imply warranties
                    of being fit for market. This Site does not represent nor
                    warrant that this Site and its Service will be secure,
                    uninterrupted or error-free. This Site holds no
                    responsibility for content that you, users, members or third
                    party posts, sends or receives through this Site. This
                    content will be accessed at your own discretion.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Automatic Card Payments
                  </Typography>
                </ThemeProvider>

                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    The purchase of auto-recurring membership subscriptions will
                    be billed to your Payment Method until your subscription is
                    canceled. Your payment information will be stored and used
                    for automatic payments. You can and may withdraw your
                    consent to automatic payments at any time. You are still
                    obligated to pay any outstanding amounts. Oppositions to a
                    past payment should be directed to info@accompanied.ca. You
                    may also contact your payment provider or bank, who can and
                    may provide further information on your rights as well as
                    applicable time limits. This Site will retain all funds
                    charged to your payment method until your subscription is
                    canceled. Deleting your account does not cancel your
                    subscription. Follow the instructions to terminate or cancel
                    your subscription that are posted on the 'Membership' page.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Third-Party Goods and Services and Links to Other Websites.
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    This Site may offer goods and services from third parties.
                    This site does not imply a guarantee of the quality of
                    accuracy of goods and services of these third parties. This
                    Site may contain links to third-party websites or services
                    that we do not own nor control. This Site is not responsible
                    for the content or policies and practices of third-party
                    websites or services linked to this Site. It is solely your
                    responsibility to read the policies and terms and conditions
                    of third-party websites before using the sites.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Applicable Law
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    These Terms and Conditions are governed by the laws of the
                    Province of Alberta. Severability Should the provisions set
                    forth in the Terms and Conditions of this Site be deemed
                    inconsistent or invalid under applicable laws, those
                    provisions will be deemed void and will be removed. Changes
                    The Terms and Conditions of this Site may be amended to
                    maintain compliance with the law and to reflect operational
                    changes of this Site. Users and members of this site will be
                    notified either by email or a notice on this Site.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    If we believe that your use of this Site contravenes
                    violates these Terms and Conditions, we reserve the right to
                    limit, suspend, or terminate your access to this Site.
                    Contact Details Please contact us if you have any questions
                    or concerns. Our contact details are as follows:
                    info@accompanied.ca You can also contact us through the
                    feedback form available on our Site.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Effective Date: 1st day of November 2023
                  </Typography>{" "}
                </ThemeProvider>

                <ThemeProvider theme={headingFont}>
                  <Typography variant="h6" mt={2}>
                    Privacy Policy
                  </Typography>
                </ThemeProvider>

                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    www.accompanied.ca Privacy Policy.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Type of Website: Social networking.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Effective date: 21st day of October, 2023.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    www.accompanied.ca (the 'Site') is owned and operated by
                    Accompanied Inc. Accompanied Inc is the data controller and can be
                    contacted at{" "}
                    <span style={{ marginLeft: "5px", marginRight: "3px" }}>
                      <a
                        href="mailto:info@accompanied.ca"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        info@accompanied.ca
                      </a>
                    </span>
                    .
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Purpose
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    The purpose of this privacy policy (this 'Privacy Policy')
                    is to inform users of our site of the following: (i) The
                    personal data we will collect (ii) Use of collected data
                    (iii) Who has access to the data collected (iv) The rights
                    of the site users This privacy policy applies in addition to
                    the terms and conditions of our Site. GDPR For users in the
                    European Union, we adhere to the Regulation (EU) 2016/679 of
                    the European Parliament of the Council of 27 April 2016,
                    known as the General Data Protection Regulation (the GDPR).
                    For users in the United Kingdom, we adhere to the GDBR as
                    enshrined in the Data Protection Act 2018. We have not
                    appointed a Data Protection Officer as we do not fall within
                    the categories of controllers and processors required to
                    appoint a Data Protection Officer under Article 37 of GDPR.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Consent
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    By using our Site, users agree that they consent to the
                    conditions set out in this privacy policy. When the legal
                    basis for us processing your personal data is that you have
                    provided your consent to that processing, you may withdraw
                    your consent at any time. If you withdraw your consent, it
                    will not make processing which we completed before you
                    withdrew your consent unlawful. You can withdraw your
                    consent by canceling your subscription and deleting your
                    account.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Legal Basis for Processing
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    We collect and process personal data about users in the EU
                    only when we have a legal basis for doing so under Article 6
                    of the GDPR. We rely on the following legal basis to collect
                    and process the personal data of users in the EU: users have
                    provided their consent to the processing of their data for
                    one or more specific purposes.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    How we use Personal Data
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    collected on our site will only be used for the purposes
                    specified in this privacy policy and indicated on the
                    relevant pages of our site. We will not use your data beyond
                    what we disclose in this Privacy Policy.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Who we share personal data with
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Employees. We may disclose user data to any member of our
                    organization who reasonably needs access to user data to
                    achieve the purposes set out in this privacy policy.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Other disclosures
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    We will not sell or share your data with other third
                    parties, except in the following cases (i) If the law
                    requires it (ii) If it is required for a legal proceeding
                    (iii) To provide or protect our legal rights and (iv) To
                    buyers or potential buyers of this company in the event we
                    seek to sell the company. If you follow hyperlinks from this
                    Site to another site, please note that we are not
                    responsible for and have no control over their privacy
                    policies and practices.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    How long we store personal data User data
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    will be stored until the user cancels their subscription and
                    deletes their account. You will be notified if your data is
                    kept for longer than this period. While we take all
                    reasonable precautions to ensure that user data is secure
                    and that users are protected, there always remains the risk
                    of harm. The internet can be insecure at times and
                    therefore, we are unable to guarantee the security of user
                    data beyond what is reasonably practical.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    International Data Transfers
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    We transfer user personal data to the following countries
                    (i) India. When we transfer user personal data, we will
                    protect that data as described in this Privacy Policy and
                    comply with applicable legal requirements for transferring
                    personal data internationally. If you are located in the UK
                    or the EU, we will only transfer peroneal data if (i) The
                    country your personal data is being transferred to has been
                    deemed to have adequate data protection by the European
                    Commission or, if you are in the UI, by the UK adequacy
                    regulations or (ii) We have implemented appropriate
                    safeguards in respect of the transfer. For example, the
                    recipient is a party to binding corporate rules, or we have
                    entered standard EU or UK data protection contractual
                    clauses with the recipient.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Your Rights as a User
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    Under the GDPR, you have the following rights. (i) Right to
                    be informed right of access, right of rectification. Rights
                    of erasure, right to restrict processing right to data
                    portability and right to object.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Children
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    The minimum age to use our website is 18 years of age. We do
                    not knowingly collect or use personal data from children
                    under 16 years of age. If we learn that we have collected
                    personal data from m a child under 16 years of age, the
                    personal data will be deleted as soon as possible. If a
                    child under 16 years of age has provided us with personal
                    data, their parent or guardian may contact our privacy
                    office.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    How to Access, Modify, Delete or Challenge the Data
                    Collected
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  {/* <Typography variant="body2" mt={2}>
                    If you would like to know if we have collected your personal
                    data, how we have used your personal data, if we have
                    disclosed your personal data, and to whom we disclosed your
                    personal data, if you would like your data to be deleted or
                    modified in any way, or if you would like to exercise any of
                    your other rights under the GDPR, please contact our privacy
                    officer at info@accompanied.ca
                  </Typography>{" "} */}
                  <Typography variant="body2" mt={2}>
                    If you would like to know if we have collected your personal
                    data, how we have used your personal data, if we have
                    disclosed your personal data, and to whom we disclosed your
                    personal data, if you would like your data to be deleted or
                    modified in any way, or if you would like to exercise any of
                    your other rights under the GDPR, please contact our privacy
                    officer at
                    <span style={{ marginLeft: "5px", marginRight: "3px" }}>
                      <a
                        href="mailto:info@accompanied.ca"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        info@accompanied.ca
                      </a>
                    </span>
                    .
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Modifications
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    This privacy policy may be amended from time to time in
                    order to maintain compliance with the law and to reflect any
                    changes to our data collection process. When we amend this
                    privacy policy, we will update the 'effective data' at the
                    top of this privacy policy. We recommend that our users
                    periodically review our Privacy Policy to ensure that they
                    are notified of any updates if necessary, we may notify
                    users by email of changes to this privacy policy.
                  </Typography>{" "}
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="h5" mt={2}>
                    {" "}
                    Complaints
                  </Typography>
                </ThemeProvider>
                <ThemeProvider theme={headingFont}>
                  <Typography variant="body2" mt={2}>
                    If you have any complaints about how we process your
                    personal data, please contact us through the contact methods
                    listed in the Contact information section so that we can,
                    where possible, resolve the issue. If you feel we have not
                    addressed your concern in a satisfactory manner you may
                    contact a supervisory authority. You also have the right to
                    directly make a complaint to a supervisory authority.
                  </Typography>{" "}
                </ThemeProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default TermsCondition;

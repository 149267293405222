import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Header2 from "../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { getTokenFromLocalStorage } from "../authService";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import chatImage from "../../assets/chat.png";
import calenderImg from "../../assets/ph_calendar-duotone.png";
import timeImg from "../../assets/ph_clock.png";
import ageImg from "../../assets/Age.png";
import genderImg from "../../assets/icons8_gender.png";
import dressImg from "../../assets/dresscode 1.png";
import locationImg from "../../assets/location2.png";
import fareImg from "../../assets/what i will Cover.png";
import descImg from "../../assets/Event_desc.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// import { Dialog, DialogContent, } from '@material-ui/core';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";

import useMediaQuery from "@mui/material/useMediaQuery";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "24px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      color: "#202226",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "31px",
    },
  },
});
const listtextStyle = {
  color: "#202226",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "31px",
};
const Notification = () => {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(0);
  const [itemSubtitle, setItemSubtitle] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSafetyConcern, setSafetyConcern] = useState(true);
  const [cancelEventText, setCancelEventText] = useState("");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between("sm", "md")
  );
  const handleClose = () => {
    setOpen(false);
    setReasonDialogOpen(false);
  };
  const handleCancelEventText = (e) => {
    setCancelEventText(e.target.value);
  };

  const handleSendGhostRequest = (
    itemSubtitle,
    itemId,
    itemEventId,
    itemSenderId
  ) => {
    const token = getTokenFromLocalStorage();
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    let requestData = {};

    if (itemSubtitle === "Event Cancelled") {
      requestData = new FormData();
      requestData.append("event_id", itemEventId);
      // requestData.append("user_id", itemSenderId);
      requestData.append("notification_id", itemId);
      requestData.append("reason_type", 1);
      requestData.append("description", "I want to make this user as ghost");
    } else if (itemSubtitle === "Event Cancelled by User") {
      requestData = {
        event_id: itemEventId,
        notification_id: itemId,
        user_id: itemSenderId,
        reason_type: 1,
        description: "test reason for ghost",
      };
    } else if (itemSubtitle === "Event Request Declined") {
      requestData = {
        receiver_id: itemSenderId,
        notification_id: itemId,
        event_id: itemEventId,
      };
    } else {
      console.error("Unsupported itemSubtitle:", itemSubtitle);
      return;
    }

    let apiEndpoint = "";

    if (itemSubtitle === "Event Cancelled by User") {
      apiEndpoint = "sendGhostRequestUser";
    } else if (itemSubtitle === "Event Cancelled") {
      apiEndpoint = "sendGhostRequest";
    } else if (itemSubtitle === "Event Request Declined") {
      apiEndpoint = "sendDeclineGhostRequest";
    } else {
      console.error("Unsupported itemSubtitle:", itemSubtitle);
      return;
    }

    axios
      .post(apiEndpoint, requestData, { headers })
      .then((response) => {
        console.log("Ghost Request Sent Successfully", response.data);

        // After sending the ghost request successfully, update notificationList
        fetchData();
      })
      .catch((error) => {
        console.error("Error sending Ghost Request", error);
      });
  };
  const handleReasonDialog = () => {
    setReasonDialogOpen(true);
  };

  // const handleShareReason = async (notification_id, event_id, user_id) => {
  //   //  debugger
  //   handleClose();
  //   const token = getTokenFromLocalStorage();
  //   //  debugger;

  //   try {
  //     if (!token) {
  //       console.error("Token not found in local storage");
  //       return;
  //     }

  //     const formData = new FormData();
  //     formData.append("notification_id", notification_id);
  //     formData.append("event_id", event_id);
  //     formData.append("user_id", user_id);
  //     formData.append("cancel_reason", cancelEventText);

  //     const response = await axios.post("shareReason", formData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     console.log("API Response:", response.data);
  //   } catch (error) {
  //     console.error("Error in API request:", error);
  //   }
  // };
  const handleClosePopup = () => {
    setShowModal(false);
  };
  const handleClick = async (eventId) => {
    try {
      const token = getTokenFromLocalStorage(); // Assuming getTokenFromLocalStorage is a function to get token
      const response = await axios.get(`eventDetails/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Handle the response, set the fetched event details in the component state or use them as required
      // console.log("Fetched event details:", response.data);

      // Format start and end dates and times
      const data = response.data.data;
      const startDate = new Date(data.starttime);
      const endDate = new Date(data.endtime);

      const startDateOptions = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      data.startDateComponent = startDate.toLocaleDateString(
        "en-US",
        startDateOptions
      );

      const startTimeOptions = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      data.startTimeComponent = startDate.toLocaleTimeString(
        "en-US",
        startTimeOptions
      );

      const endDateOptions = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      data.endDateComponent = endDate.toLocaleDateString(
        "en-US",
        endDateOptions
      );
      const endTimeOptions = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      data.endTimeComponent = endDate.toLocaleTimeString(
        "en-US",
        endTimeOptions
      );

      // Set the event details in the component state
      setEventDetails(data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching event details:", error);
      // Handle errors, show error message, etc.
    }
  };

  const fetchData = useCallback(async () => {
    const token = getTokenFromLocalStorage();

    try {
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const response = await axios.get("notificationlist", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: page + 1,
          per_page: rowsPerPage,
        },
      });

      const notificationListData =
        response.data && response.data.data && Array.isArray(response.data.data)
          ? response.data.data
          : [];

      setTotalRecords(response.data.total_record || 0);

      if (Array.isArray(notificationListData)) {
        setNotificationList(notificationListData);

        console.log("Notification List Length:", notificationListData.length);
      } else {
        console.error("Invalid response format. Expected an array.");
      }

      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[55px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />

        <Grid container spacing={3} sx={{ width: "100%", margin: "auto" }}>
          {headerLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
              }}
            >
              <MoonLoader color="#863895" size={80} />
            </div>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" align="left" gutterBottom>
              Notifications
              {/* ({notificationList.length })*/}
            </Typography>
            <TableContainer
              component={Paper}
              style={{
                backgroundColor: "rgba(160, 195, 255, 0.06)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Table>
                <TableHead></TableHead>
                {notificationList.length === 0 ? (
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 800,
                      textAlign: "center",
                      // borderRadius: "16px",
                      mx: "auto", // Center the Card horizontally
                      p: 5, // Add padding to the Card content
                      boxSizing: "border-box", // Ensure padding doesn't affect the width
                      border: "none",
                      backgroundColor: "rgba(160, 195, 255, 0.06)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={chatImage}
                        alt="Mobile"
                        style={{
                          width: "100%",
                          maxWidth: "350px",
                          height: "auto",
                        }}
                      />
                    </div>
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: {
                            fontFamily: "Lora",
                            fontSize: "36px",
                            fontWeight: 700,
                            lineHeight: "46px",
                            letterSpacing: "0em",
                            textAlign: "center",
                            xs: "1.0rem", // Adjust the font size for extra-small screens
                            sm: "1.2rem", // Adjust the font size for small screens and above
                          },
                        }}
                      >
                        No notifications yet
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <TableBody>
                    {notificationList.map((item, index) => (
                      <TableRow
                        key={index}
                        // onClick={() => handleClick(item.event_id)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell onClick={() => handleClick(item.event_id)}>
                          <Avatar>
                            <img
                              src={item.sender_details.profile_url}
                              alt="Sample"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </Avatar>
                          {/* {console.log(item.sender_details.profile_img)} */}
                        </TableCell>
                        <TableCell onClick={() => handleClick(item.event_id)}>
                          <span
                            style={{
                              color:
                                item.type == 1 || item.type == 2
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {item.sub_title}
                          </span>
                        </TableCell>
                        <TableCell onClick={() => handleClick(item.event_id)}>
                          <span style={{ marginRight: "10px" }}>
                            {item.title}
                          </span>
                        </TableCell>

                        <TableCell colSpan={3}>
                          {item.type === "4" && (
                            <Button
                              variant="contained"
                              onClick={() =>
                                handleSendGhostRequest(
                                  item.sub_title,
                                  item.id,
                                  item.event_id,
                                  item.sender_id
                                )
                              }
                              style={{
                                backgroundColor: "#863895",
                                color: "#fff",
                                fontSize: "12px",
                                padding: "8px 14px",
                                ...(item.ghost_request === 1 && {
                                  backgroundColor: "rgba(134, 56, 149, 0.21)",
                                  cursor: "not-allowed",
                                }),
                              }}
                              disabled={item.ghost_request === 1}
                            >
                              {item.ghost_request === 1
                                ? "Ghost Request Submitted"
                                : "Send Ghost Request"}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell onClick={() => handleClick(item.event_id)}>
                          <span style={{ fontWeight: "bold" }}>
                            {item.create_date}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>

          {showModal && (
            <Dialog
              open={showModal}
              //  onClose={handleClosePopup}
              maxWidth="sm" // Set maximum width to 600px
              PaperProps={{
                style: {
                  background: "#ffffff",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
                },
              }}
            >
              <IconButton
                onClick={handleClosePopup}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#863895",
                  border: "2px solid #863895",
                  borderRadius: "50%",
                  padding: "8px",
                  backgroundColor: "#fff",
                }}
              >
                <CloseIcon style={{ fontSize: 16 }} />{" "}
                {/* Adjust the font size here */}
              </IconButton>

              <DialogContent>
                <Card
                  // key={index}
                  sx={{
                    width: "100%",
                    borderRadius: "20px",
                    // minWidth: "600px"
                    // width: "100%"
                    // margin: "5px",

                    // marginBottom: index < eventList.length - 1 ? "20px" : 0,
                  }}
                >
                  {/* <IconButton
            onClick={handleClosePopup}
            style={{
              position: "absolute",
              top: "30px",
              right: "30px",
              color: "#863895",
              border: "2px solid #863895",
              borderRadius: "50%",
              padding: "8px",
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon style={{ fontSize: 16 }} />{" "}
          
          </IconButton> */}

                  <CardContent>
                    <Grid
                      container
                      mb={2}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        marginLeft: "20px",
                      }}
                    >
                      <ThemeProvider theme={headingFont}>
                        <Typography variant="h5" mb={1}>
                          {eventDetails.event_name}
                        </Typography>
                      </ThemeProvider>

                      <ThemeProvider theme={headingFont}>
                        <Typography variant="subtitle1">
                          {eventDetails.description}
                        </Typography>
                      </ThemeProvider>
                    </Grid>

                    <List aria-label="contacts">
                      <ListItem>
                        <img
                          src={calenderImg}
                          alt="EventImage"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "12px",
                          }}
                        />

                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.startDateComponent} To{" "}
                            {eventDetails.endDateComponent}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={timeImg}
                          alt="EventImage"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "12px",
                          }}
                        />

                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.startTimeComponent} To{" "}
                            {eventDetails.endTimeComponent}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={ageImg}
                          alt="EventImage"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "12px",
                          }}
                        />
                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.age_from} to {eventDetails.age_to}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={genderImg}
                          alt="EventImage"
                          style={{
                            width: "22px",
                            height: "22px",
                            marginRight: "12px",
                          }}
                        />
                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {(() => {
                              const genderId = parseInt(
                                eventDetails.gender,
                                10
                              );

                              switch (genderId) {
                                case 1:
                                  return "Male";
                                case 2:
                                  return "Female";
                                case 3:
                                  return "";
                                case 4:
                                  return "All";
                                default:
                                  return "Unknown";
                              }
                            })()}{" "}
                            {""} {eventDetails.gender_description}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={dressImg}
                          alt="EventImage"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "12px",
                          }}
                        />
                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.dresscode}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={locationImg}
                          alt="EventImage"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "12px",
                          }}
                        />

                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.location}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={fareImg}
                          alt="EventImage"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "12px",
                          }}
                        />

                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.eventfeature}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <img
                          src={descImg}
                          alt="EventImage"
                          style={{
                            width: "24px",
                            height: "25px",
                            marginRight: "12px",
                          }}
                        />

                        <ListItemText>
                          <Typography style={listtextStyle} variant="subtitle2">
                            {eventDetails.activity_details}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </DialogContent>
            </Dialog>
          )}
        </Grid>
      </div>
    </>
  );
};

export default Notification;
{
  /*{item.type === "5" && (
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleReasonDialog(
                                item.id,
                                item.event_id,
                                item.sender_id
                              )
                            }
                            style={{
                              backgroundColor: "#863895",
                              color: "#fff",
                              fontSize: isSmallScreen
                                ? "8px"
                                : isMediumScreen
                                ? "10px"
                                : "12px",
                              padding: isSmallScreen
                                ? "4px 8px"
                                : isMediumScreen
                                ? "6px 10px"
                                : "8px 14px",
                            }}
                          >
                            Share Reason
                          </Button>
                          )}*/
}

{
  /* <Dialog
                        open={reasonDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="reason-dialog-title"
                        aria-describedby="reason-dialog-description"
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <DialogTitle
                          id="reason-dialog-title"
                          sx={{
                            color: "#222",
                            fontFamily: "Raleway",
                            fontSize: "20px !important ",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Reason for Cancellation
                        </DialogTitle>
                        <DialogContent>
                          <Stack direction="row" spacing={2}>
                            <Button
                              onClick={() => {
                                setSafetyConcern(true);
                              }}
                              sx={{
                                width: "100%",
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: isSafetyConcern
                                  ? "rgba(134, 56, 149, 0.75)"
                                  : "none",
                                color: isSafetyConcern ? "#FFF" : "#863895",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              Safety Concern
                            </Button>
                            <Button
                              onClick={() => {
                                setSafetyConcern(false);
                              }}
                              sx={{
                                width: "100%",
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: isSafetyConcern
                                  ? "none"
                                  : "rgba(134, 56, 149, 0.75)",
                                color: isSafetyConcern ? "#863895" : "#FFF",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              Other
                            </Button>
                          </Stack>

                          <TextField
                            id="name"
                            onChange={(e) => handleCancelEventText(e)}
                            value={cancelEventText}
                            placeholder={
                              isSafetyConcern
                                ? "Tell us about the safety concern..."
                                : "Tell us what happened..."
                            }
                            label=""
                            type="name"
                            multiline
                            fullWidth
                            variant="outlined"
                            rows={6}
                            sx={{
                              marginTop: "20px",
                              '&:focus': {
                                outline: 'none',
                                borderColor: '#ced4da',
                              },
                              '&& textarea:focus': {
                                boxShadow: 'none !important',
                                borderColor: '#863895 !important',
                              },
                            }}
                           
                          />
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }}>
                          {<Button
                              onClick={() => {
                                handleShareReason(item.id, item.event_id, item.sender_id);
                                console.log("item.id:", item.id);
                                console.log("item.event_id:", item.event_id);
                                console.log("item.sender_id:", item.sender_id);
                              }}
                            
                            sx={{
                              width: "100%",
                              maxWidth: 200,
                              border: "1px solid #863895",
                              borderRadius: "6px",
                              backgroundColor: "rgba(134, 56, 149, 0.75)",
                              "&:hover": {
                                backgroundColor: "rgba(134, 56, 149, 0.75)",
                                border: "1px solid #863895",
                                color: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                            }}
                          >
                            Confirm
                          </Button>}
                        </DialogActions>
                        </Dialog>*/
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDkHAzp6QydMLLHHpHG9f5RcDhuEBy7eD4",
  authDomain: "accompanied-95ab0.firebaseapp.com",
  projectId: "accompanied-95ab0",
  storageBucket: "accompanied-95ab0.appspot.com",
  messagingSenderId: "973863211692",
  appId: "1:973863211692:web:93a91cfbfdebc7cfb14509",
  measurementId: "G-75X68VZV6Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);


const storage = getStorage(app);
const db = getFirestore(app);
const usersRef = collection(db, "users");

const updateOnlineStatus = async (userId, online) => {
  try {
    if (userId && userId.trim() !== "") {
      const userDocRef = doc(usersRef, `${userId}`);
     
      await setDoc(
        userDocRef,
        { online, lastSeen: online ? null : new Date() },
        { merge: true }
      );
    } else {
      // console.error("Error updating online status: Invalid userId");
    }
  } catch (error) {
    // console.error("Error updating online status:", error);
  }
};

const initFirebase = () => {
  onAuthStateChanged(auth, (user) => {
    // console.log("onAuthStateChanged callback executed");
    // console.log("User object:", user);

    if (user) {
      // console.log("User is authenticated:", user);

      const userId = user.uid; // Extract userId from authenticated user
      // console.log("**userId extracted from user object:**", userId);

      if (userId && userId.trim() !== "") {
        // updateOnlineStatus(userId, true); 

        // Listen for offline events (no changes needed here)
        onSnapshot(doc(usersRef, userId), (docSnapshot) => {
          // console.log("Snapshot data:", docSnapshot.data());
          const data = docSnapshot.data();
          if (!data.online) {
            // console.log("User went offline:", userId);
          }
        });
      } else {
        console.error("Invalid userId during authentication");
      }

      // const userEmail = auth.currentUser.email;
      // console.log("Current user email:", userEmail);
    } else {
      console.log("User is not authenticated");
    }
  });
};



const logout = async () => {
  try {
    const userId = auth.currentUser.uid;
    if (userId) {
      // Update online status before signing out
      // console.log("**userId passed to updateOnlineStatus during logout:**", userId);

      await updateOnlineStatus(userId, false);

      // Update the lastSeen timestamp to the current server time
      const userDocRef = doc(usersRef, userId);
      await setDoc(userDocRef, { lastSeen: serverTimestamp() }, { merge: true });
    }

    // Sign out the user
    await signOut(auth);
    // console.log("User logged out");
  } catch (error) {
    // console.error("Logout Failed:", error);
  }
};


export {
  app,
  auth,
  storage,
  db,
  getFirestore as firestore,
  initFirebase,
  logout,
  updateOnlineStatus,
  usersRef,
};

// const initFirebase = () => {
//   // Set up listener for authentication state changes
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       console.log("User is authenticated:", user);
//       // Set user status to online
//       addDoc(doc(usersRef, user.uid), { online: true }, { merge: true });
//     } else {
//       console.log("User is not authenticated");
//     }
//   });
// }
// const logout = async () => {
//   try {
//     const user = auth.currentUser;
//     if (user) {
//       updateOnlineStatus(user.uid, false);
//     }

//     await signOut(auth);
//     console.log("User logged out");
//   } catch (error) {
//     console.error("Logout Failed:", error);
//   }
// };

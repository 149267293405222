import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { auth, db, updateOnlineStatus } from "../Firebase";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  updateDoc,
  getDocs,
  doc,
  setDoc,
  Timestamp,
} from "firebase/firestore";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  function logOut() {
    return signOut(auth);
  }

  async function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, googleAuthProvider);
    } catch (err) {
      alert(err.message);
    }
  }

  function detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  const profileCreation = (user, profileImage) => {
    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const userData = {
          uid: user.uid,
          displayName: user.displayName
            ? user.displayName
            : user.email.match(/^([^@]*)@/)[1],
          created_at: Timestamp.now(),
          email: user.email,
        };

        if (profileImage !== undefined) {
          userData.profileImage = profileImage;
        }

        setDoc(userDocRef, userData).then(() => {});
      } catch (err) {
        console.error("Firestore error during profile creation:", err);
        window.confirm(
          `Something went wrong during profile creation. Please retry. Error: ${err.message}`
        ) === true && window.location.reload();
      }
    }
  };

  const SendDataToFirebase = async (
    email,
    fullName,
    password,
    blocked,
    ...profileImages
  ) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
  
      await addFirestore(
        user.uid,
        email,
        fullName,
        blocked,
        profileImages,
        false
      );
  
      profileCreation(user);
    } catch (error) {
      console.error(
        `Error during registration - Email: ${email}, FullName: ${fullName}`,
        error.code,
        error.message
      );
  
      if (error.code === "auth/invalid-email") {
        toast.error("Invalid email format. Please provide a valid email address.");
      } else if (error.code === "auth/weak-password") {
        toast.error("Weak password. Please provide a stronger password.");
      } else if (error.code === "auth/email-already-in-use") {
        // Attempt to sign in with existing credentials
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          await updateFirestore(
            userCredential.user.uid,
            email,
            fullName,
            blocked,
            profileImages,
            true
          );
  
          console.log("User signed in successfully!");
  
          profileCreation(userCredential.user, profileImages);
  
          updateOnlineStatus(userCredential.user.uid, true);
        } catch (signInError) {
          console.error("Error signing in existing user:", signInError);
          toast.error("Registration failed. Please try again.");
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };
  

  const addFirestore = async (
    uid,
    email,
    fullName,
    blocked,
    profileImage,
    online
  ) => {
    const userDocRef = doc(db, "users", uid);

    await setDoc(userDocRef, {
      uid: uid,
      displayName: fullName,
      created_at: Timestamp.now(),
      email: email,
      profileImage: profileImage,
      blocked: blocked || false,
      online: online || false,
    });
  };

  const updateFirestore = async (
    uid,
    email,
    fullName,
    blocked,
    profileImage,
    online
  ) => {
    const userDocRef = doc(db, "users", uid);
    await updateDoc(userDocRef, {
      fullName: fullName,
      email: email,
      blocked: blocked,
      profile: profileImage,
      online: online,
    });
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        profileCreation(currentUser);
      }
      setLoading(false);
      setError(null);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        loading,
        googleSignIn,
        logOut,
        error,
        SendDataToFirebase,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

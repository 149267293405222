import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography,IconButton  } from '@mui/material';
import { Link } from 'react-router-dom';
import CardActions from "@mui/material/CardActions";
import { Close as CloseIcon } from '@mui/icons-material'; 
import { useNavigate } from 'react-router-dom';

const MembershipPopup = ({ open, onClose }) => {
    const [showLoginMessage, setShowLoginMessage] = useState(false);

    const navigate = useNavigate();
 

  const handleConfirmLogin = () => {
    // Navigate to the login page
    onClose();
    navigate('/login');
  };

  const handleConfirmSignup = () => {
    // Navigate to the login page
    onClose();
    navigate('/sign_up');
  };
  const handleGetSubscription = () => {
    setShowLoginMessage(true);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        margin: 'auto',
        overflowX: 'hidden',
        // backgroundColor: '#F0F0F0', 
        // border: '1px solid #863895', // Border color
      }}
    >
      
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{
            width: '100%', // 100% width on all screen sizes
            maxWidth: '1200px', // Limit maximum width for larger screens if needed
            mx: 'auto', // Center the container horizontally
            px: 2, // Add horizontal padding
          }}
        >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
       
        <Typography
          sx={{
            color: "var(--General-Dark, #222)",

            fontFamily: "Lora",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "28.938px",
            fontFeatureSettings: `'clig' off, 'liga' off`,
            marginTop: "12px",
          }}
        >
          Subscription Features
        </Typography>
        
        <ul
          style={{
            marginLeft: "54px",
            marginTop: "43px",
            color: "#202226",
            listStyle: "unset",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "31px",
          }}
        >
          <li>Create user profile</li>
          <li>Respond to any posted activity and event</li>
          <li>
            Send request that a profile be ghost-tagged in the
            unlikely scenario that you are ghosted at an event
          </li>
          <li>Post activities or events</li>
          <li>Community of real people </li>
          <li>Instant chat feature</li>
          <li>
            Share your activity or event directly to another profile
          </li>
          <li>Tagging and search filters</li>
          <li>Clean, uncomplicated web design</li>
          <li>
            Lower monthly cost for users who commit to a longer
            subscription period
          </li>
          <li>Billing and membership management</li>
          <li>
            After free trial period has ended, subscription is
            converted into paid subscription and auto-renews at the
            end of each billing cycle
          </li>
          <li>
          Changes to the subscription plan, features or cost will be
            communicated to subscribers and/or members either by email
            or posted to this site
          </li>
          <li>
            Cancel or change your subscription anytime and still be
            able to use your subscription for the time you've already
            paid
          </li>
          <li>Easy cancellation</li>
        </ul>
        <Typography
        sx={{
          marginTop: "16px",
          color: "#F4460F",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "31px",
        }}
      >
        *All currency is in Canadian dollars. 
      </Typography>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
          }}
        >
         
      <CardActions>
        {showLoginMessage ? (
          <Typography  sx={{
            color: "#222222",
            fontFamily: "Lora",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "18px",
            textTransform: "none !important",
          }}>
            Sign Up/Sign In to get your membership
            <Button onClick={handleConfirmLogin}  variant="contained"
            sx={{
              border: "1px solid #863895",
              marginLeft: "10px",
              backgroundColor: "#863895 !important",
              padding: "8px 35px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#863895",
                color: "#FFF",
              },
              color: "#FFF",
              fontFamily: "Lora",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "none !important",
              fontFeatureSettings: `'clig' off, 'liga' off`,
              
            }}>Sign In</Button>
             <Button onClick={handleConfirmSignup}  variant="contained"
            sx={{
              border: "1px solid #863895",
              marginLeft: "10px",
              backgroundColor: "#863895 !important",
              padding: "8px 35px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#863895",
                color: "#FFF",
              },
              color: "#FFF",
              fontFamily: "Lora",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "none !important",
              fontFeatureSettings: `'clig' off, 'liga' off`,
              
            }}>Sign Up</Button>
          </Typography>
        ) : (
          <Button
            onClick={handleGetSubscription}
            sx={{
              border: "1px solid #863895",
              backgroundColor: "#863895",
              borderRadius: "3.808px",
              "&:hover": {
                backgroundColor: "#863895",
                color: "#FFF",
              },
              color: "#FFF",
              fontFamily: "Lora",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "none !important",
              fontFeatureSettings: `'clig' off, 'liga' off`,
              display: "inline-flex",
              padding: "13.708px 25.892px",
              alignItems: "center",
              gap: "9.138px",
            }}
            variant="contained"
          >
            Get Subscription
          </Button>
        )}
      </CardActions>
        </Grid>
      </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ position: 'absolute', right: 20, top: 12 }}>
        <IconButton color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        {/* Add other action buttons if needed */}
      </DialogActions>
    </Dialog>
  );
};

export default MembershipPopup;

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Text } from "../Text";
import Header from "../Header/Header";
import Header2 from "../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TransgenderIcon from "@mui/icons-material/Transgender";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import BentoIcon from "@mui/icons-material/Bento";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Footer from "../FooterPages/Footer";
import { getTokenFromLocalStorage } from "../authService";
import { Link } from "react-router-dom";
import acceptedImg from "../../assets/Accepted Event.png";
import { MoonLoader } from "react-spinners";
import calenderImg from "../../assets/ph_calendar-duotone.png";
import timeImg from "../../assets/ph_clock.png";
import ageImg from "../../assets/Age.png";
import genderImg from "../../assets/icons8_gender.png";
import dressImg from "../../assets/dresscode 1.png";
import locationImg from "../../assets/location2.png";
import fareImg from "../../assets/what i will Cover.png";
import descImg from "../../assets/Event_desc.png";
import round_add from "../../assets/duotone.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lato",
      fontSize: "28px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "24px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      // width: '90px',
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});
const listtextStyle = {
  color: "#202226",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingLeft: "10px",
};

const buttonStyle = {
  border: "1px solid #863895",
  color: "#863895",
  fontFamily: "Lora",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  textTransform: "none",
  position: "relative",
  width: "142px",
  height: "42px",
  borderRadius: "4px",
};
const imgStyle = {
  width: "20px",
  height: "20px",
  marginLeft: "12px",
  marginTop: "-18px", // Add bottom margin to adjust the position
};

const AcceptedEvents = () => {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [isSafetyConcern, setSafetyConcern] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [acceptedEventList, setAcceptedEventList] = useState([]);
  const [listStatus0, setListStatus0] = useState([]);
  const [listStatus1, setListStatus1] = useState([]);
  const [cancelEventId, setCancelEventId] = useState("");
  const [cancelEventText, setCancelEventText] = useState("");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [directionUserId, setDirectionUserId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const popupStyle = {
    width: "178px",
    height: "68px",
    borderRadius: "6px",
    background: "#fff",
    boxShadow: "0px 1px 6px 2px rgba(0, 0, 0, 0.15)", // Add the drop shadow here
    display: showPopup ? "block" : "none", // Ensuring the display is block by default
    position: "absolute",
    top: "-80px", // Adjust the top position as needed
    left: "0",
    padding: "10px",
    fontSize: "12.433px",
    color: "#5B5B5B",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showPopup) {
        const popup = document.getElementById("popup");
        if (popup && !popup.contains(event.target)) {
          setShowPopup(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  const handleClickOpen = (itemId) => {
    setOpen(true);
    setCancelEventId(itemId);
  };

  const handleClose = () => {
    setOpen(false);
    setReasonDialogOpen(false);
  };

  const handleYesClick = () => {
    setOpen(false);
    setReasonDialogOpen(true);
  };
  const handleCancelEventText = (e) => {
    setCancelEventText(e.target.value);
  };

  const handleButtonClick = async (item) => {
    try {
      if (item) {
        const itemId = item.id;
        const token = getTokenFromLocalStorage();
        console.log("Event ID:", itemId);

        const response = await axios.get(`withdrawRequest/${itemId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          console.log("Request withdrawn successfully");
          const updatedList = items.filter(
            (listItem) => listItem.id !== itemId
          );

          setItems(updatedList);
          console.log("Updated List:", updatedList);
          window.location.reload();
        } else {
          console.error(
            "Error sending request:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error("Event object is undefined or null.");
      }
    } catch (error) {
      console.error("Error sending request:", error.message);
    }
  };
  // const handleSetDirectionUserId = (userId) => {
  //   setDirectionUserId(userId); // Update state with the received directionUserId
  // };
  const handleDirectionClick = (eventId, directionUserId) => {
    const token = getTokenFromLocalStorage();

    axios
      .post(
        "/check_event_direction",
        {
          event_id: eventId,
          user_id: directionUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200 && response.data.success) {
          const url = response.data.data.url;
          window.open(url, "_blank");
        } else if (response.data.status === 400) {
          const message = response.data.message;
          if (message === "Your request not accepted") {
            setShowPopup(true); // Set showPopup state to true
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleReasonClick = () => {
    handleClose();

    const formData = new FormData();
    formData.append("remarks", cancelEventText);
    formData.append("event_id", cancelEventId);

    const token = getTokenFromLocalStorage();

    axios
      .post("cancelAttendEvent", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenFromLocalStorage();

      try {
        if (!token) {
          console.error("Token not found in local storage");
          return;
        }

        const acceptedResponse = await axios.get("myAcceptedEvent", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setData(acceptedResponse.data);
        // setLoading(false);
        // console.log("Data fetched successfully:", acceptedResponse.data);

        const acceptedEventListData =
          acceptedResponse.data &&
          acceptedResponse.data.data &&
          Array.isArray(acceptedResponse.data.data)
            ? acceptedResponse.data.data
            : [];

        // console.log("accepted Event List Data:", acceptedEventListData);
        acceptedEventListData.forEach(function (eventData) {
          var userId = eventData.user_id;
          setDirectionUserId(userId);
        });
        // debugger

        if (Array.isArray(acceptedEventListData)) {
          setAcceptedEventList(acceptedEventListData);

          const listStatus0 = acceptedEventListData.filter(
            (item) => item.status === "0"
          );
          const listStatus1 = acceptedEventListData.filter(
            (item) => item.status === "1"
          );

          setListStatus0(listStatus0);
          setListStatus1(listStatus1);
          acceptedEventListData.forEach((acceptedEvent) => {
            const data = acceptedEvent;

            if (data.event_details && data.event_details.starttime) {
              const startDate = new Date(data.event_details.starttime);
              const endDate = new Date(data.event_details.endtime);

              data.startDateComponent = startDate.toDateString();
              data.startTimeComponent = startDate.toLocaleTimeString();
              data.endDateComponent = endDate.toDateString();
              data.endTimeComponent = endDate.toLocaleTimeString();

              // Perform a null/undefined check for 'event_name'
              if (data.event_details.event_name) {
                // Access 'event_name' only if it exists
                // console.log("Event Name:", data.event_details.event_name);
              } else {
                console.error("'event_name' is null or undefined.");
              }
            } else {
              console.error(
                "Invalid date format. 'starttime' is null or undefined."
              );
            }
          });
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    // if (!localStorage.getItem("status")) {
    fetchData();
    // }
  }, []);

  // setDirectionUserId();

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[40px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
          // setDirectionUserId={handleSetDirectionUserId}
        />
        <Grid container spacing={5} sx={{ width: "100%", margin: "auto" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={
              listStatus1.length > 0 || (listStatus0 && listStatus0.length > 0)
                ? 8
                : 12
            }
            lg={
              listStatus1.length > 0 || (listStatus0 && listStatus0.length > 0)
                ? 8
                : 12
            }
            xl={
              listStatus1.length > 0 || (listStatus0 && listStatus0.length > 0)
                ? 8
                : 12
            }
          >
            {headerLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 9999,
                }}
              >
                <MoonLoader color="#863895" size={80} />
              </div>
            )}
            <ThemeProvider theme={headingFont}>
              {listStatus1.length > 0 ||
              (listStatus0 && listStatus0.length > 0) ? (
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "25px", marginLeft: "15px" }}
                >
                  I'm Attending ({listStatus1.length})
                </Typography>
              ) : null}
            </ThemeProvider>

            <Stack spacing={1}>
              {listStatus1.length === 0 &&
              listStatus0 &&
              listStatus0.length === 0 ? (
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 1300,
                    textAlign: "center",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(160, 195, 255, 0.06)",
                  }}
                >
                  <img
                    src={acceptedImg}
                    alt="Mobile"
                    style={{
                      width: "35%", // Initial width for larger screens
                      height: "auto", // Maintain aspect ratio
                      "@media screen and (max-width: 600px)": {
                        width: "50% !important",
                      },
                    }}
                  />

                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        marginTop: 2,
                        color: "#2F2F2F",
                        textAlign: "center",
                        fontFamily: "Lora",
                        fontSize: "32px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                        "@media screen and (max-width: 600px)": {
                          fontSize: "24px", // Adjust the font size for smaller screens
                        },
                        "@media screen and (max-width: 400px)": {
                          fontSize: "16px", // Further adjust the font size for even smaller screens
                        },
                      }}
                    >
                      No events to show. Please create an event.
                    </Typography>
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <Button
                        sx={{
                          marginTop: 2,
                          backgroundColor: "#863895",
                          "&:hover": {
                            backgroundColor: "#863895",
                          },
                          "@media screen and (max-width: 600px)": {
                            width: "60%", // Adjust the button width for smaller screens
                            fontSize: "9px", // Adjust the font size for smaller screens
                          },
                        }}
                        variant="contained"
                      >
                        Explore Events
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              ) : (
                listStatus1.map((item) => (
                  <Card
                    key={item.id}
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                      width: "100%",
                      margin: "auto",
                      marginBottom: "10px",
                      borderRadius: "24px",
                      backgroundColor: "rgba(160, 195, 255, 0.03)",
                    }}
                  >
                    <CardContent>
                      <Stack m={2}>
                        <ThemeProvider theme={headingFont}>
                          <Typography variant="h5" mb={1}>
                            {" "}
                            {item.event_details.event_name}
                          </Typography>
                        </ThemeProvider>
                        <ThemeProvider theme={headingFont}>
                          <Typography variant="body2">
                            {item.event_details.description}
                          </Typography>
                        </ThemeProvider>
                      </Stack>
                      <List aria-label="contacts">
                        <ListItem>
                          {/* <CalendarMonthIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={calenderImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.startDateComponent} To{" "}
                              {item.endDateComponent}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <ScheduleIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={timeImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.startTimeComponent} To{" "}
                              {item.endTimeComponent}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <DateRangeIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={ageImg}
                            alt="EventImage"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.event_details.age_from} to{" "}
                              {item.event_details.age_to}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <TransgenderIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={genderImg}
                            alt="EventImage"
                            style={{
                              width: "22px",
                              height: "22px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {(() => {
                                const genderId = parseInt(
                                  item.event_details.gender,
                                  10
                                );

                                switch (genderId) {
                                  case 1:
                                    return "Male";
                                  case 2:
                                    return "Female";
                                  case 3:
                                    return "";
                                  case 4:
                                    return "All";
                                  default:
                                    return "Unknown";
                                }
                              })()}{" "}
                              {""} {item.event_details.gender_description}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <DryCleaningIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={dressImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.event_details.dresscode}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <LocationOnIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={locationImg}
                            alt="EventImage"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.event_details.location}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          {/* <BentoIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          /> */}
                          <img
                            src={fareImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.event_details.eventfeature}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ErrorOutlineIcon
                            sx={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.event_details.activity_details}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <Stack
                          direction={{ xs: "column", sm: "row", md: "row" }}
                          spacing={2}
                          justifyContent="center" // Center horizontally
                          alignItems="center" // Center vertically
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              border: "1px solid #FF2B2B !important",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 35px",
                              gap: "10px",
                              borderRadius: "4px",
                              // maxWidth: "50%",
                              // marginTop: "2px",
                              "&:hover": {
                                backgroundColor: "none",
                              },
                            }}
                            onClick={() =>
                              handleClickOpen(item.event_details.id)
                            }
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                color: "#FC3434",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              I'd No Longer Like to Attend
                            </Typography>
                          </Button>

                          <div style={{ position: "relative" }}>
                            <button
                              style={buttonStyle}
                              onClick={() =>
                                handleDirectionClick(
                                  item.event_details.id,
                                  directionUserId
                                )
                              }
                            >
                              Directions
                              <img
                                src={round_add}
                                alt="logo"
                                style={imgStyle}
                              />
                            </button>
                            {/* {showPopup && (
                              <div id="popup" style={popupStyle}>
                                Exact location will be provided to the attendee.
                              </div>
                            )} */}
                          </div>
                        </Stack>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          sx={{ textAlign: "center" }}
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            sx={{
                              color: "#222",
                              fontFamily: "Raleway",
                              fontSize: "20px !important ",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Are you sure you'd like to cancel your event?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id="alert-dialog-description"
                              sx={{
                                color: "#383838",
                                fontFamily: "Lora",
                                fontSize: "14px !important ",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textAlign: "center",
                              }}
                            >
                              Cancelling within 48 hours of an event may result
                              in your profile being tagged.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <Button
                              onClick={handleClose}
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                "&:hover": {
                                  backgroundColor: "none",
                                  border: "1px solid #863895",
                                },
                                color: "#863895",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              No
                            </Button>
                            <Button
                              onClick={handleYesClick}
                              sx={{
                                width: "100%",
                                maxWidth: 200,

                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: "rgba(134, 56, 149, 0.75)",
                                "&:hover": {
                                  backgroundColor: "rgba(134, 56, 149, 0.75)",
                                  border: "1px solid #863895",
                                  color: "#FFF",
                                },
                                color: "#FFF",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                              autoFocus
                            >
                              Yes, Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Dialog
                          open={reasonDialogOpen}
                          onClose={handleClose}
                          aria-labelledby="reason-dialog-title"
                          aria-describedby="reason-dialog-description"
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <DialogTitle
                            id="reason-dialog-title"
                            sx={{
                              color: "#222",
                              fontFamily: "Raleway",
                              fontSize: "20px !important ",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Reason for Cancellation
                          </DialogTitle>
                          <DialogContent>
                            <Stack direction="row" spacing={2}>
                              <Button
                                onClick={() => {
                                  setSafetyConcern(true);
                                }}
                                sx={{
                                  width: "100%",
                                  border: "1px solid #863895",
                                  borderRadius: "6px",
                                  backgroundColor: isSafetyConcern
                                    ? "rgba(134, 56, 149, 0.75) !important"
                                    : "none",
                                  color: isSafetyConcern ? "#FFF" : "#863895",
                                  fontFamily: "Lora",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                  fontFeatureSettings: `'clig' off, 'liga' off`,
                                }}
                              >
                                Safety Concern
                              </Button>
                              <Button
                                onClick={() => {
                                  setSafetyConcern(false);
                                }}
                                sx={{
                                  width: "100%",
                                  border: "1px solid #863895",
                                  borderRadius: "6px",
                                  backgroundColor: isSafetyConcern
                                    ? "none"
                                    : "rgba(134, 56, 149, 0.75) !important",
                                  color: isSafetyConcern ? "#863895" : "#FFF",
                                  fontFamily: "Lora",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                  fontFeatureSettings: `'clig' off, 'liga' off`,
                                }}
                              >
                                Other
                              </Button>
                            </Stack>

                            <TextField
                              id="name"
                              onChange={(e) => handleCancelEventText(e)}
                              placeholder={
                                isSafetyConcern
                                  ? "Tell us about the safety concern..."
                                  : "Tell us what happened..."
                              }
                              label=""
                              type="name"
                              multiline
                              fullWidth
                              variant="outlined"
                              rows={6}
                              sx={{
                                marginTop: "20px",
                                "&:focus": {
                                  outline: "none",
                                  borderColor: "#ced4da",
                                },
                                "&& textarea:focus": {
                                  boxShadow: "none !important",
                                  borderColor: "#863895 !important",
                                },
                              }}
                            />
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <Button
                              onClick={handleReasonClick}
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: "rgba(134, 56, 149, 0.75)",
                                "&:hover": {
                                  backgroundColor: "rgba(134, 56, 149, 0.75)",
                                  border: "1px solid #863895",
                                  color: "#FFF",
                                },
                                color: "#FFF",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </List>
                    </CardContent>
                  </Card>
                ))
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <ThemeProvider theme={headingFont}>
              {listStatus0 && listStatus0.length > 0 ? (
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "25px", marginLeft: "15px" }}
                >
                  I'd Like to Attend ({listStatus0.length})
                </Typography>
              ) : null}
            </ThemeProvider>
            {listStatus0 && listStatus0.length > 0 ? (
              <Card
                sx={{
                  borderRadius: "24px",
                  background: "#FFF",
                  padding: "20px", 
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  backgroundColor: "rgba(160, 195, 255, 0.03)",

                  minHeight: "300px", 
                }}
              >
                <Stack spacing={3} sx={{ flex: 1 }}>
                  {listStatus0.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        width: "100%",
                        maxWidth: 450,
                        margin: "auto",
                        borderRadius: "8px",
                        border: "1px solid #C8C8C8",
                        background: "#EDEDED",
                      }}
                    >
                      <CardContent>
                        <Stack m={1}>
                          <ThemeProvider theme={headingFont}>
                            <Typography variant="h5">
                              {item.event_details.event_name}
                            </Typography>
                          </ThemeProvider>
                        </Stack>
                        <List aria-label="contacts">
                          <ListItem>
                            {/* <CalendarMonthIcon
                              sx={{
                                width: "24px",
                                height: "25px",
                              }}
                            /> */}
                            <img
                              src={calenderImg}
                              alt="EventImage"
                              style={{
                                width: "24px",
                                height: "25px",
                              }}
                            />
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.startDateComponent} To{" "}
                                {item.endDateComponent}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            {/* <DryCleaningIcon
                              sx={{
                                width: "24px",
                                height: "25px",
                              }}
                            /> */}
                            <img
                              src={dressImg}
                              alt="EventImage"
                              style={{
                                width: "24px",
                                height: "25px",
                              }}
                            />
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.event_details.dresscode}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <img
                              src={locationImg}
                              alt="EventImage"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            {/* <LocationOnIcon
                              sx={{
                                width: "24px",
                                height: "25px",
                              }}
                            /> */}
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.event_details.location}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            border: "1px solid #FF2B2B",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px 25px",
                            gap: "5px",
                            borderRadius: "2px",
                            "&:hover": {
                              backgroundColor: "none",
                            },
                          }}
                          onClick={() => handleButtonClick(item.event_details)}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              color: "#FC3434",
                              fontFamily: "Lora",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                            }}
                          >
                            Withdraw Interest
                          </Typography>
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </Stack>
              </Card>
            ) : null}
          </Grid>
        </Grid>

        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AcceptedEvents;

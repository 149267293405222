import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { auth, firestore } from "../Firebase";
import ResetPassword from "./ResetPassword";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import Google from "../assets/Google__G__Logo.png";
import facebook from "../assets/facebook.png";
import Divider from "@mui/material/Divider";
import { Img } from "./Img";
import { Text } from "./Text";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "react-toastify/dist/ReactToastify.css";
import Link from "@mui/material/Link";
import ForgetPassword from "./ForgetPassword";
import { getTokenFromLocalStorage } from "./authService";
import { useUserAuth } from "../context/userAuthContext";
import Layout from "../Components/Layout";
import { Box, Card, CardContent } from "@mui/material";
import db from "../Firebase";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { saveTokenToLocalStorage, AUTH_TOKEN_KEY } from "./authService";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import "../fonts.css";
import OtpPopup from "./OtpPopup";
import AccountVerify from "./AccountVerify";
// import Header from "./Header/Header";
import HeaderLogin from "./Header/HeaderLogin";

const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [openAccountVerify, setOpenAccountVerify] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isRecoverDialogOpen, setRecoverDialogOpen] = React.useState(false);
  const [accountRecoverDialogOpen, setAccountRecoverDialogOpen] =
    React.useState(false);

  const [showResetPasswordPopup, setShowResetPasswordPopup] =
    React.useState(false);
  const [recoverEmail, setRecoverEmail] = React.useState("");
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  const [user1, setUser1] = React.useState([]);
  const [openOtpPopup, setOpenOtpPopup] = React.useState(false);
  const [profile, setProfile] = React.useState([]);
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [forgetLoading, setForgetLoading] = React.useState(false);
  const [isForgetPassword, setIsForgetPassword] = React.useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPassword, setCustomerPassword] = useState("");
  const { error, SignUp, currentuser } = useUserAuth();
  const [recoverLoading, setRecoverLoading] = React.useState(false);

  const { googleSignInWithPopup, user, detectMob, googleSignIn } =
    useUserAuth();

  // const handleSignInFirebase = async (e, provider) => {
  //   e.preventDefault();
  //   try {
  //     switch (provider) {
  //       case 'google':
  //         await detectMob() ? googleSignIn() : googleSignInWithPopup();
  //         navigate('/chat');
  //         break;

  //       default:
  //         break;
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  useEffect(() => {
    if (user) {
      // navigate('/chat');
    }
  }, [user]);

  // const card = (
  //   <React.Fragment>
  //     <CardContent>
  //       <Typography variant='h4' color='text.secondary' gutterBottom>
  //         Penguins Chat
  //       </Typography>
  //       <Box width='100%'>
  //         <div className='google-btn' role={'button'} onClick={(e) => handleSignInFirebase(e, 'google')}>
  //           <div className='google-icon-wrapper'>
  //             <img
  //               className='google-icon'
  //               src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
  //               alt='google'
  //             />
  //           </div>
  //           <p className='btn-text'>
  //             <b>Login with Google</b>
  //           </p>
  //         </div>
  //       </Box>
  //     </CardContent>
  //   </React.Fragment>
  // );
  const handleRecoverAccount = async () => {
    if (!recoverEmail) {
      toast.error("Email is required");
      return;
    }

    setRecoverLoading(true);
    try {
      const response = await axios.post("restore_user_account", {
        email: recoverEmail,
      });
      toast.success("Account Recovered Successfully");
      setRecoverLoading(false);

      setAccountRecoverDialogOpen(false);
      // setOpenOtpPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    user_type: "1",
    login_type: "1",
  });

  const { email, password } = formData;

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });

    if (field === "password" || field === "retypePassword") {
      setPasswordError("");
    }
    if (field === "email") {
      setEmailError("");
    }

    if (field === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(e.target.value)) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError("");
      }
    }
  };

  const handleCloseRecoverDialog = () => {
    setRecoverDialogOpen(false);
  };
  const handleCloseAccountRecoverDialog = () => {
    setAccountRecoverDialogOpen(false);
  };

  const handleContinueWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser1(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user1) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user1.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user1.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          console.log("User Profile:", res.data);
          const userEmail = res.data.email;
          console.log("User Email:", userEmail);

          axios
            .post("socialMediaLogin", { email: userEmail, login_type: 2 })
            .then((response) => {
              console.log("Social Media Login API Response:", response.data);

              if (response.status === 200 && response.data.success) {
                const token = response.data.data.token;
                console.log(token);
                saveTokenToLocalStorage(token);

                navigate("/home");
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  }, [user1, setProfile, navigate, toast]);

  // const logOut = () => {
  //   googleLogout();
  //   setProfile(null);
  // };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsForgetPassword(true);
    setRecoverDialogOpen(true);
    setForgetLoading(false);
  };

  // const handleContinueWithFacebook = () => {
  //   window.location.href = "your_facebook_redirect_url";
  // };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    if (emailError) {
      setIsToastOpen(true);

      toast.error("Please fill valid email before proceeding");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3700);
      setLoginLoading(false);

      return;
    }

    if (!formData.email || !formData.password) {
      setIsToastOpen(true);

      toast.error("Please fill in all the required fields");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3700);

      setLoginLoading(false);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Please fill in all the required fields",
      // });
      return;
    }

    try {
      // console.log("Before axios.post call");
      const response = await axios.post(
        "login",
        {
          email: formData.email,
          password: formData.password,
          user_type: formData.user_type,
          login_type: formData.login_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      

      if (
        response.data.status === 400 &&
        response.data.message ===
          "You've used diffrent login method at time the of signed up"
      ) {

        toast.error(
          "You've used a different login method at the time of sign-up",
          { autoClose: 1500 }
        );
        setLoginLoading(false);
        handleContinueWithGoogle();
        console.log("After handleContinueWithGoogle");
      } else if (response.data.status === 200) {
        // Handle successful login
        const token = response.data.data.token;
        const userId = response.data.data.id;
        saveTokenToLocalStorage(token, userId);
// debugger
        toast.success(
          response.data.message || "You've successfully logged in",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
          }
        );

        setTimeout(() => {
          navigate("/home");
        }, 2000);

        setLoginLoading(false);
      } else {
        // Handle other error scenarios
        const responseBody = response.data;
        setLoginLoading(false);

        if (
          response.data.status === 400 &&
          responseBody.message === "Account has not verified yet"
        ) {
          setOpenAccountVerify(true);
        setLoginLoading(false);

        
        } else if (responseBody.message === "Email Password Doesn't Match") {
          toast.error("Email or password is incorrect", { autoClose: 1500 });

          setLoginLoading(true);

          setTimeout(() => {
            setLoginLoading(false);
          }, 2000);
        } else if (responseBody.message === "Account Has Been Deleted") {
          toast.error("You have deleted your account", { autoClose: 1500 });

          setAccountRecoverDialogOpen(true); // Open the recovery dialog

          setLoginLoading(true);

          setTimeout(() => {
            setLoginLoading(false);
          }, 2000);
        }
       else if (responseBody.message === "User doesn't exist") {
        toast.error("Please enter a valid email", { autoClose: 1500 });


        setLoginLoading(true);

        setTimeout(() => {
          setLoginLoading(false);
        }, 2000);
      }
        
        else {
          console.error("Sign In Failed:", responseBody.message);
        }
      }
    } catch (error) {
      setLoginLoading(false);
      console.error("Error during sign-in:", error);
    }
  };

  // useEffect(() => {
  //   // if (localStorage.getItem('authToken')) {
  //   //   navigate('/home')
  //   // }
  // }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenFromLocalStorage();
      const apiUrl = "header";

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (
          !token ||
          (response.data.status === 401 && !response.data.success)
        ) {
          console.error(
            "Token not found in local storage or unauthorized API access"
          );
          console.log("Token:", token);
          console.log("Response:", response);

          if (response.data.status === 401 && !response.data.success) {
            // Navigate to the login page
            navigate("/login");
          }

          return; // Stop further execution if token is not present or unauthorized access
        }

        if (
          response.status === 200 &&
          response.data.success &&
          token &&
          response.data.status === 200
        ) {
          const responseData = response.data.data;

          // Navigate to the home page
          navigate("/home");
        } else {
          console.error("Failed to get data:", response.data.message);
        }
      } catch (error) {
        console.error("Error while making the API request:", error.message);

        // Navigate to the login page in case of an error
        navigate("/login");
      }
    };

    fetchData();
  }, [navigate]);

  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div
        className="flex flex-col font-lato gap-[35px] mx-auto w-full"
        style={{
          background: "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
        }}
      >
        <HeaderLogin />
        <div
          style={{
            // background:
            //   "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container
            style={{
              padding: 20,
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paper
              elevation={3}
              style={{
                padding: 20,
                height: "auto", // Set height to auto
              }}
            >
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <img
                    src="images/img_rectangle406.png"
                    alt="LoginImage"
                    style={{
                      width: "100%",
                      height: "auto",
                      // height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={3} sx={{}}>
                  <Grid container justifyContent="center" spacing={2}>
                    <div className="bg-header flex md:flex-1 flex-col items-center justify-center mb-[13px] p-1 rounded-md w-[30%] md:w-full">
                      <div className="flex flex-col items-center justify-center w-full md:w-full xs:w-full">
                        <Img
                          className="h-[80px]"
                          src="images/img_group1171274834.svg"
                          alt="group1171274834"
                        />
                        <Text
                          className="mt-[7px] text-[8.87px] text-white-A700 tracking-[2.22px]"
                          size="txtLatoBold887"
                        >
                          ACCOMPANIED
                        </Text>
                        <Text
                          className="text-[4.43px] text-white-A700_8c tracking-[1.11px]"
                          size="txtInterRegular443"
                        >
                          LIVELY. SAVVY. PEOPLE
                        </Text>
                      </div>
                    </div>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      direction="column"
                    >
                      <form>
                        <div>
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "38%",
                                left: 0,
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              <MailOutlineIcon />
                            </div>
                            <input
                              style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                borderColor: "transparent",
                                boxShadow: "none",
                                color: "inherit",
                                width: "calc(100% - 2px)",
                                borderBottom: "2px solid gray",
                                paddingLeft: "36px",
                                outline: "none",
                                background: "transparent",
                                marginBottom: "10px",
                              }}
                              id="input-with-icon-textfield"
                              placeholder="Email"
                              required
                              name="email"
                              value={email}
                              onChange={(e) => handleChange(e, "email")}
                              onKeyDown={(e) =>
                                e.key === "Enter" && handleSignIn(e)
                              }
                            />
                          </div>

                          {emailError && (
                            <p style={{ color: "red" }}>{emailError}</p>
                          )}
                        </div>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "32%",
                              left: 0,
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "gray",
                            }}
                          >
                            <HttpsOutlinedIcon />
                          </div>
                          <input
                            style={{
                              fontFamily: "Lora, serif",
                              fontWeight: 400,
                              borderColor: "transparent",
                              boxShadow: "none",
                              color: "inherit",
                              width: "calc(100% - 2px)",
                              borderBottom: "2px solid gray",
                              marginBottom: "16px",
                              paddingLeft: "36px",
                            }}
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            required
                            name="password"
                            value={password}
                            onChange={(e) => handleChange(e, "password")}
                            onKeyDown={(e) =>
                              e.key === "Enter" && handleSignIn(e)
                            }
                          />
                          <IconButton
                            aria-label="toggle password visibility"
                            style={{
                              position: "absolute",
                              top: "32%",
                              right: 0,
                              transform: "translateY(-50%) translateX(-50%)",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginTop: "8px",
                            marginLeft: "auto", // Added marginLeft to move the content to the left
                          }}
                        >
                          <div>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={handleForgotPassword}
                              style={{
                                fontFamily: "Lora,",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "left",
                                color: "#8098F9",
                              }}
                            >
                              Forgot Password?
                              {forgetLoading ? (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: "black",
                                    // position: 'absolute',
                                    // top: '50%',
                                    left: "50%",
                                    // marginTop: '-12px',
                                    // marginLeft: '-12px',
                                  }}
                                />
                              ) : null}
                            </Link>
                          </div>
                        </div>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{
                            background:
                              "linear-gradient(90deg, #8098F9 -0.77%, #2A45B2 -0.76%, #F98080 107.17%) !important",
                            marginTop: "10px",
                            position: "relative",
                          }}
                          onClick={(e) => handleSignIn(e)}
                          disabled={loginLoading || isToastOpen}
                        >
                          {loginLoading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                          Sign In
                        </Button>
                      </form>
                      <Dialog
                        //  backdrop="static"
                        open={accountRecoverDialogOpen}
                        onClose={handleCloseAccountRecoverDialog}
                        maxWidth="xs"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          borderRadius: "20px",
                        }}
                      >
                        <DialogTitle
                          style={{
                            textAlign: "center",
                            position: "relative",
                          }}
                        >
                          <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseAccountRecoverDialog}
                            aria-label="close"
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "12px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                          <div>
                            <p
                              style={{
                                color: "#09090B",
                                fontFamily: "Lora",
                                fontSize: "28px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                marginTop: "55px",
                              }}
                            >
                              Recover Your Account
                            </p>
                            <p
                              style={{
                                color: "#464646",
                                fontFamily: "Lora",
                                fontSize: "13.727px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "20.59px",
                                textAlign: "center",
                                marginTop: "15px",
                              }}
                            >
                              No problem! Enter the email address you used to
                              sign In, and we'll help you to recover your
                              account.
                            </p>
                          </div>
                        </DialogTitle>
                        <DialogContent>
                          <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ marginTop: "32px" }}
                              >
                                Email
                              </Typography>
                              <TextField
                                placeholder="please enter your email"
                                value={recoverEmail}
                                onChange={(e) => {
                                  setRecoverEmail(e.target.value);
                                }}
                                // variant="standard"
                                fullWidth
                                sx={{
                                  width: "100%",
                                  "& fieldset": {
                                    borderColor: "#863895 !important",
                                  },
                                  "& [type='text']:focus": {
                                    boxShadow: "none", // Remove box shadow on focus for type='text'
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} textAlign="center">
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleRecoverAccount}
                                disabled={recoverLoading}
                                sx={{
                                  backgroundColor: "#863895 !important",
                                  color: "#fff",
                                  marginTop: "10px",
                                  marginBottom: "75px",
                                }}
                              >
                                {recoverLoading && (
                                  <CircularProgress
                                    size={24}
                                    sx={{
                                      color: "white",
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      marginTop: "-12px",
                                      marginLeft: "-12px",
                                    }}
                                  />
                                )}
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </DialogContent>
                      </Dialog>
                      {/* <div className="text-center my-3">
                      <span
                        className="fw-500 fs-16 text-white"
                        style={{ marginRight: "10px" }}
                      >
                        Not a member yet?
                      </span>

                      <Link
                        to="/sign_up"
                        component="button"
                        variant="body2"
                        style={{
                          fontFamily: "Lora,",
                          fontSize: "16px",
                          fontWeight: 700,
                          lineHeight: "24px",
                          letterSpacing: "0em",
                          textAlign: "left",
                          color: "#8098F9",
                        }}
                        onClick={() => navigate("/sign_up")}
                      >
                        Sign Up
                      </Link>
                    </div> */}
                      <Divider sx={{ marginTop: "16px", marginBottom: "16px" }}>
                        or continue with
                      </Divider>
                      <Stack
                        direction="row"
                        spacing={2}
                        mt={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleContinueWithGoogle}
                          sx={{
                            color: "#09090B !important",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "16px",
                          }}
                        >
                          <img
                            src={Google}
                            alt="Google"
                            style={{
                              width: "14px",
                              height: "14px",
                              marginRight: "8px",
                            }}
                          />
                          Google
                        </Button>

                        <Button
                          variant="outlined"
                          // onClick={handleContinueWithFacebook}
                          sx={{
                            color: "#09090B !important",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "16px",
                          }}
                        >
                          <img
                            src={facebook}
                            alt="Facebook"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                          Facebook
                        </Button>
                      </Stack>
                      {/* <Link to="/information" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      width: "16%", // Adjust width as needed for responsiveness
                      height: "4%", // Adjust height as needed for responsiveness
                      background:
                        "linear-gradient(90deg, #8098F9 -0.77%, #2A45B2 -0.76%, #F98080 107.17%)",
                      color: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column", // Set flexDirection to 'column'
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      position: "absolute",
                     
                       zIndex: 1,
                      borderRadius: "8px",
                    }}
                  >
                    <ArrowBackIosIcon />
                  </div>
                </Link> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          {openAccountVerify ? (
            <AccountVerify
              openAccountVerify={openAccountVerify}
              setOpenAccountVerify={setOpenAccountVerify}
              setOpenOtpPopup={setOpenOtpPopup}
              email={formData.email}
            />
          ) : null}

          {openOtpPopup && (
            <OtpPopup
              openOtpPopup={openOtpPopup}
              setOpenOtpPopup={setOpenOtpPopup}
              isForgetPassword={isForgetPassword}
              setIsForgetPassword={setIsForgetPassword}
              email={isForgetPassword ? recoverEmail : formData.email}
              setShowResetPasswordPopup={setShowResetPasswordPopup}
            />
          )}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />

          {showResetPasswordPopup ? (
            <ResetPassword
              openResetPasswordPopup={showResetPasswordPopup}
              setOpenResetPasswordPopup={setShowResetPasswordPopup}
              email={isForgetPassword ? recoverEmail : formData.email}
              setIsForgetPassword={setIsForgetPassword}
            />
          ) : null}
          {isRecoverDialogOpen ? (
            <ForgetPassword
              setRecoverDialogOpen={setRecoverDialogOpen}
              isRecoverDialogOpen={isRecoverDialogOpen}
              recoverEmail={recoverEmail}
              setRecoverEmail={setRecoverEmail}
              openOtpPopup={openOtpPopup}
              setOpenOtpPopup={setOpenOtpPopup}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default LoginPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
// import { Button } from "../Button";
import { Text } from "../Text";
import Button from "@mui/material/Button";
import contactimg from "../../assets/contactimg.png";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../FooterPages/Footer";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Header from "../Header/Header";
import Header2 from "../Header/Header2";
import { MoonLoader } from "react-spinners";
import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import { Stack } from "@mui/material";
import { getTokenFromLocalStorage } from "../authService";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import { Card, CardContent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const headingFont = createTheme({
  typography: {
    h4: {
      color: "#FF3932;",
      fontFamily: "Barlow",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h6: {
      color: "#5A5A5A",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h5: {
      color: "#5A5A5A",
      fontFamily: "Roboto",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    body2: {
      width: "85px",
      color: "#A3A3A3",
      fontFamily: "Barlow",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
      marginTop: "5px",
      marginLeft: "5px",
    },
  },
});

const ContactUs = () => {
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [userIdExists, setUserIdExists] = React.useState(false);
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    description: "",
    reason: "",
  });

  const handleChange = (e, field) => {
    // console.log("Field:", field);
    // console.log("Value:", e.target.value);
    // debugger
    setFormData({ ...formData, [field]: e.target.value });
    // console.log("FormData:", formData);
  };

  const handleButtonClick = async () => {
    setLoginLoading(true);
    // Check if any of the required fields are empty
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.description ||
      !formData.reason
    ) {
      // Show an error toast message for empty fields
      toast.error("Please fill in all the required fields");
      setLoginLoading(false);
      return; // Exit the function if any field is empty
    }

    // Check if the email follows the pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      // Show an error toast message for invalid email
      toast.error("Please enter a valid email address");
      setLoginLoading(false);

      return; // Exit the function if email is invalid
    }

    // Check if the phone number follows the pattern (numeric)
    const phonePattern = /^\d+$/;
    if (!phonePattern.test(formData.phone)) {
      // Show an error toast message for non-numeric phone number
      toast.error("Please enter a valid numeric phone number");
      setLoginLoading(false);

      return; // Exit the function if phone number is invalid
    }

    const apiUrl = "contactUs";
    const requestData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      description: formData.description,
      reason: formData.reason,
    };

    try {
      const response = await axios.post(apiUrl, requestData);
      console.log("API response:", response.data);

      if (response.data.status === 200) {
        // Reset the form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
          reason: "",
        });

        // Show a success toast message
        toast.success("Thank you for contacting us");
        setLoginLoading(false);
      } else {
        // Show an error toast message for API failure
        toast.error("API call failed. Please try again.");
        setLoginLoading(false);
      }
    } catch (error) {
      console.error("API error:", error);

      // Show an error toast message for API failure
      toast.error("API call failed. Please try again.");
      setLoginLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserIdExists(response.data.data.id);
        // debugger
        // console.log("Profile Data:", response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[55px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        {userIdExists ? (
          <Header2
            setHeaderData={setHeaderData}
            setHeaderLoading={setHeaderLoading}
          />
        ) : (
          <Header />
        )}
        <Card  sx={{
              
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                backgroundColor: "rgba(160, 195, 255, 0.03)",
              }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack m={2} spacing={2}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "58px",
                      color: "#212121",
                      lineHeight: "normal",
                    }}
                  >
                    Get in
                    <span
                      style={{
                        color: "#863895",
                      }}
                    >
                      Touch
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#282828",
                      marginTop: "20px",
                    }}
                  >
                    Need to report a profile, want some information, or just
                    have a suggestion? Please fill in the fields below and we'll
                    be in touch. We're interested in hearing everything you have
                    to say!
                  </Typography>
                </Stack>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                  xl={10}
                  direction="column"
                  m={2}
                >
                  <TextField
                    id="outlined-required"
                    margin="dense"
                    placeholder="Enter your Name"
                    onChange={(e) => handleChange(e, "name")}
                    value={formData.name}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                  <TextField
                    id="outlined-required"
                    margin="dense"
                    placeholder="Enter your email address"
                    onChange={(e) => handleChange(e, "email")}
                    value={formData.email}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />

                  <TextField
                    id="outlined-required"
                    margin="dense"
                    placeholder="Enter your phone number"
                    onChange={(e) => handleChange(e, "phone")}
                    value={formData.phone}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                  <TextField
                    id="outlined-required"
                    margin="dense"
                    placeholder="Share your reason"
                    onChange={(e) => handleChange(e, "reason")}
                    value={formData.reason}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lato",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />

                  <TextField
                    id="outlined-required"
                    margin="dense"
                    multiline
                    rows={4}
                    placeholder="Enter your Message"
                    onChange={(e) => handleChange(e, "description")}
                    value={formData.description}
                    fullWidth
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&:focus": {
                        outline: "none",
                        borderColor: "#ced4da",
                      },
                      "&& textarea:focus": {
                        boxShadow: "none !important",
                        borderColor: "#863895 !important",
                      },
                    }}
                  />
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      "&:focus": {
                        outline: "none",
                        borderColor: "#ced4da",
                      },
                      "&& textarea:focus": {
                        boxShadow: "none !important",
                        borderColor: "#863895 !important",
                      },
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        maxWidth: 200,
                        border: "1px solid #863895",
                        borderRadius: "6px",
                        backgroundColor: "#863895",
                        "&:hover": {
                          backgroundColor: "#863895",
                          border: "1px solid #863895",
                          color: "#FFF",
                        },
                        color: "#FFF",
                        fontFamily: "Lora",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        textTransform: "none !important",
                        fontFeatureSettings: `'clig' off, 'liga' off`,
                        marginTop: "20px",
                      }}
                      onClick={handleButtonClick}
                      disabled={loginLoading || isToastOpen}
                    >
                      {loginLoading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      Send
                    </Button>
                  </Stack>
                  <Box height={4} />
                  <Stack direction="row" spacing={2}>
                    <WifiCalling3OutlinedIcon />
                    <div>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "26px",
                          letterSpacing: "0.02em",
                          textAlign: "left",
                          color: "#000000",
                        }}
                      >
                        Phone
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "18px",
                          textAlign: "left",
                          color: "#863895",
                        }}
                      >
                        1-587-223-9574
                      </Typography>
                    </div>
                    <LocalPostOfficeOutlinedIcon />
                    <div>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "26px",
                          letterSpacing: "0.02em",
                          textAlign: "left",
                          color: "#000000",
                        }}
                      >
                        Email
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "18px",
                          textAlign: "left",
                          color: "#863895",
                        }}
                      >
                        info@accompanied.ca
                      </Typography>
                    </div>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}></Stack>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "26px",
                        letterSpacing: "0.02em",
                        textAlign: "left",
                        color: "#000000",
                      }}
                    >
                      Mon. - Fri. 7 a.m. to 5 p.m. MST
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack justifyContent="flex-start" alignItems="center">
                  <img
                    src={contactimg}
                    alt="tieTwo"
                    style={{
                      width: "500px", // Set the width to 300 pixels
                      height: "450px", // Set the height to 200 pixels
                      marginTop: "40px",
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import { Img } from "../Img";
import { Line } from "../Line";
import { Text } from "../Text";
import imagefoot from "../../assets/footer_text.svg";
import styled from "styled-components";
import MembershipPopup from "./MembershipPopup";

const StyledText = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 955px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: #ffffff; /* Set text color to white */
  text-decoration: none;
  margin-right: 32px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 22px;
  }
`;
const Footer = () => {
 

  return (
    <>
      <div className="bg-purple-600 flex flex-col font-roboto items-center justify-end mx-auto pt-[10px] w-full">
        <div className="flex flex-col items-center justify-start w-full">
          <Img
            className="h-[37px] mt-8"
            // src="images/img_group1171275242.svg"
            src={imagefoot}
            alt="group1171275242"
          />
          <div className="flex flex-col gap-[15px] items-center justify-center mt-[35px]  w-[60%] md:w-full">
            <div className="flex flex-col items-center justify-center w-full md:w-[60%] md:mt-6 lg:mt-8">
              <div className="flex flex-col sm:flex-row gap-[15px] items-center justify-center w-full">
                <StyledText>
                  <StyledLink to="/faq">FAQs</StyledLink>
                  <StyledLink to="/terms&condition">
                    Terms & Conditions
                  </StyledLink>
                  <StyledLink to="/contact_us">Contact Us</StyledLink>
                  <StyledLink to="/safety_guidelines" >
                    Safety Guidelines
                  </StyledLink>
                </StyledText>
              </div>
            </div>

            <Text
              style={{
                color: "#DDD",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px", // 171.429%
              }}
            >
              Going it alone can be a rewarding experience as it allows for
              introspection and personal growth, but there is something special
              about sharing your journey with others. You can go alone but you
              can also go accompanied.
            </Text>
          </div>
          <div className="flex flex-row md:gap-10 items-start justify-between mt-11 w-full">
            <div className="bg-purple-800 h-[52px] md:px-5 rounded-tr-[100px] w-[41%] "></div>

            {/* Add the © Accompanied2023 text with styles */}
            <div className="flex flex-col items-start justify-center md:flex-row md:px-5">
              <p
                style={{
                  color: "white",
                  fontSize: 12,
                  fontFamily: "Raleway",
                  fontWeight: 400,
                  // lineHeight: 4,
                  wordWrap: "break-word",
                  textAlign: "center", // Center text on small screens
                  order: 2, // Change the order on small screens
                  marginTop: "10px", // Add some top margin for better spacing
                }}
                className="md:order-1" // Reset the order on medium screens and above
              >
                © Accompanied Inc .
              </p>
            </div>

            <div
              className="bg-purple-800 h-[52px] md:px-5  w-[41%]"
              style={{
                borderTopLeftRadius: "180px",
              }}
            ></div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Footer;

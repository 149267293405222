import React from 'react'
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-toastify/dist/ReactToastify.css";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContentText from "@mui/material/DialogContentText";

const AccountVerify = ({
	openAccountVerify,
	setOpenAccountVerify,
	setOpenOtpPopup,
	email,
}) => {

	const [verifyLoading, setVerifyLoading] = React.useState(false);


	const handleClose = () => {
    setOpenAccountVerify(false);
  };

	const handleClickOpen = async () => {
		setVerifyLoading(true);
    try {
      const response = await axios.post(
        "resendotp",

        {
          email: email,
        }
      );

      if (response.status === 200) {
        toast.success("OTP Resend successfully");
        setOpenOtpPopup(true);
		setVerifyLoading(false);
        setOpenAccountVerify(false);
      } else {
		setVerifyLoading(false);
        
      }

      console.log("API Response:", response.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      toast.error("OTP Not Send. Please try again.");
    }
  };

  return (
    <div>
			<Dialog
			// fullScreen={fullScreen}
			open={openAccountVerify}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			maxWidth="xs"
			fullWidth
			style={{ width: "400px", margin: "auto" }}
		>
			<DialogTitle id="responsive-dialog-title"></DialogTitle>
			<DialogContent>
				<DialogContentText
					style={{
						fontFamily: "Lora",
						fontStyle: "normal",
						fontWeight: "700",
						fontSize: "20px",
						color: "#212121",
						lineHeight: "23px",
						marginTop: "-05px",
					}}
				>
					It appears that your account has not been verified
					Yet!
				</DialogContentText>
				<div className="flex flex-col items-center">
					<div className="flex items-center justify-center">
						<img
							src="/images/verify.png"
							alt=""
							style={{ maxWidth: "100%", height: "auto" }}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions style={{ justifyContent: "center" }}>
				<Button
					onClick={handleClickOpen}
					autoFocus
					style={{
						borderRadius: "8.579px",
						backgroundColor: "#953F97",
						color: "#fff",
					}}
					disabled={verifyLoading}
				>
				{verifyLoading && (
					<CircularProgress
					  size={24}
					  sx={{
						color: "white", 
						position: "absolute",
						top: "50%", 
						left: "50%", 
						marginTop: "-12px",
						marginLeft: "-12px", 
					  }}
					/>
				  )}
					Verify now
				</Button>
			</DialogActions>
			</Dialog>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				rtl
				pauseOnFocusLoss
				draggable
				pauseOnHover
				closeButton={false}  

			/>
    </div>
  )
}

export default AccountVerify

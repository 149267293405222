import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import "react-toastify/dist/ReactToastify.css";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";



import { toast, ToastContainer } from "react-toastify";

import axios from "axios";

const ResetPassword = ({
  openResetPasswordPopup,
  setOpenResetPasswordPopup,
  email,
  setIsForgetPassword,
}) => {
  const navigate = useNavigate();

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [resetPasswordFormData, setResetPasswordFormData] = React.useState({
    password: "",
    passwordConfirmation: ""
  });
  const [showReenterPassword, setShowReenterPassword] = React.useState(false);

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowReenterPassword = () => {
    setShowReenterPassword(!showReenterPassword);
  };

  const handleCloseResetPasswordPopup = () => {
    setResetPasswordFormData({
      ...resetPasswordFormData,
      password: "",
      passwordConfirmation: ""
    });
    setIsForgetPassword(false);
    setOpenResetPasswordPopup(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleSavePassword = async () => {
  //   try {
  //     if (resetPasswordFormData.password !== resetPasswordFormData.passwordConfirmation) {
  //       toast.error("Passwords do not match");
  //       return;
  //     }
  
  //     const response = await axios.post(
  //       "resetPassword",
  //       {
  //         email: email,
  //         new_password: resetPasswordFormData.password,
  //       }
  //     );
  
  //     const successMessage = response.data.message;
  //     setResetPasswordFormData({
  //       ...resetPasswordFormData,
  //       password: "",
  //       passwordConfirmation: ""
  //     });
  //     setIsForgetPassword(false);
  //     toast.success(successMessage, {
  //       onClose: () => {
  //         setOpenResetPasswordPopup(false);
  //         navigate("/login");
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error changing password:", error.message);
  
  //     if (error.response && error.response.status === 400 && error.response.data.message === "The old password can not be the New password.") {
  //       toast.error("The old password cannot be the new password");
  //     } else {
  //       toast.error("An error occurred while changing the password");
  //     }
  
  //     setResetPasswordFormData({
  //       ...resetPasswordFormData,
  //       password: "",
  //       passwordConfirmation: ""
  //     });
  //     setIsForgetPassword(false);
  //   }
  // };
  const handleSavePassword = async () => {
    try {
      if (resetPasswordFormData.password !== resetPasswordFormData.passwordConfirmation) {
        toast.error("Passwords do not match");
        return;
      }
  
      const response = await axios.post(
        "resetPassword",
        {
          email: email,
          new_password: resetPasswordFormData.password,
        }
      );
  
      const message = response.data.message;
  
      setResetPasswordFormData({
        ...resetPasswordFormData,
        password: "",
        passwordConfirmation: ""
      });
  
      setIsForgetPassword(false);
  
      if (response.data.status === 200) {
        // Handle success (status 200) and redirect to login page
        toast.success(message, {
          onClose: () => {
            setOpenResetPasswordPopup(false);
            navigate("/login");
          },
        });
       } else if (response.data.status === 400 && !response.data.success && response.data.message === "The new password field is required.") {
          // Show toast message for missing new password
          toast.error("Please enter your new password");
      } else if (response.data.status === 400) {
        // Handle client error (status 400) and show error message
        toast.error(message);
  
        // Reset password form data in case of a client error
        setResetPasswordFormData({
          ...resetPasswordFormData,
          password: "",
          passwordConfirmation: ""
        });
  
        // Check if the specific error message is present
        if (message === "The email must be a valid email address.") {
          toast.error("Please try again with a password different than current password", {
            onClose: () => {
              setOpenResetPasswordPopup(false);
              
            },
          });
        }
      } else {
        // Handle other status codes if needed
        toast.error("An error occurred while changing the password");
      }
  
    } catch (error) {
      console.error("Error changing password:", error.message);
  
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
  
        // Reset password form data in case of a client error
        setResetPasswordFormData({
          ...resetPasswordFormData,
          password: "",
          passwordConfirmation: ""
        });
  
        // Check if the specific error message is present
        if (errorMessage === "The email must be a valid email address.") {
          // Handle the specific error and set setIsForgetPassword to false
          setIsForgetPassword(false);
        }
      } else {
        toast.error("An error occurred while changing the password");
      }
  
      setIsForgetPassword(false);
    }
  };
  
  
  

  return (
    <div>
      <Dialog open={openResetPasswordPopup} onClose={handleCloseResetPasswordPopup}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Enter New Password"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={resetPasswordFormData.password || ''}
          onChange={(e) =>
            setResetPasswordFormData({
              ...resetPasswordFormData,
              password: e.target.value,
            })
          }
          sx={{
            "& fieldset": {
              borderColor: "#863895 !important",
            },
            "&.MuiInputBase-colorPrimary": {
              color: "#fff", 
            },
            "& [type='password']:focus, & [type='text']:focus": {
              boxShadow: "none", // Remove box shadow on focus for type='text' and type='password'
              // borderColor: "#863895 !important",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showNewPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
        placeholder="Re-enter New Password"
          type={showReenterPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={resetPasswordFormData.passwordConfirmation || ''}
          onChange={(e) =>
            setResetPasswordFormData({
              ...resetPasswordFormData,
              passwordConfirmation: e.target.value,
            })
          }
          sx={{
            "& fieldset": {
              borderColor: "#863895 !important",
            },
            "&.MuiInputBase-colorPrimary": {
              color: "#fff", // Use 'inherit' or the desired color to override
            },
            "& [type='password']:focus, & [type='text']:focus": {
              boxShadow: "none", // Remove box shadow on focus for type='text' and type='password'
              // borderColor: "#863895 !important",
            },
          }}
          
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowReenterPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showReenterPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSavePassword}
          sx={{
            backgroundColor: "#863895 !important",
            color: "#fff",
            marginTop: "10px",
          }}
        >
          Save Password
        </Button>
      </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}  

        />
    </div>
  )
}

export default ResetPassword

